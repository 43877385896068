@import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap');
.brd {
  border: 1px solid red;
}

.about-business-descpr {
  width: 100%;
  height: 350px;
  padding: 1em;
  overflow: auto;
}
.about-business-content fieldset {
  border: 3px solid black !important;
  border-radius: 15px 15px;
}
.about-business-content fieldset legend {
  text-align: center;
  width: auto !important;
}

.about-business-content p {
  text-align: justify;
  text-justify: inter-word;
}

.about-business-images-wrap {
  margin-top: 6px;
}

.about-business-images {
  display: flex;
  justify-content: space-evenly;
}
.about-business-images img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.about-business-info-card {
  display: flex;
  justify-content: space-around;
  height: 50px;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.about-business-info-card-role {
  display: flex;
  justify-content: space-evenly;
  border-radius: 0px 20px 20px 0px;
}
.info-card-orange {
  background-color: orange;
}

.info-card-green {
  background-color: #9fb43b;
}
.info-card-teal {
  background-color: #2ba4ad;
}
.info-card-musk {
  background-color: #fdbc62;
}
.info-card-red {
  background-color: red;
}

.about-business-info-card-role img {
  border-radius: 10px 10px;
  background-color: white;
  /* padding: 5px; */
}

.aboutFpoGallerySwiper .swiper-slide {
  text-align: -webkit-center;
}

/* .aboutFpoGallerySwiper .swiper-button-prev {
  display: none;
}


.aboutFpoGallerySwiper .swiper-button-next {
  display: none;
} */

.productServicesTitle {
  margin-bottom: 0.5em;
  /* color: orange; */
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
  -webkit-animation: productServicesTitle 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: productServicesTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes productServicesTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.galleryTitle {
  margin-bottom: 0.5em;
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
  -webkit-animation: galleryTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: galleryTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.licensesTitle {
  color: orange;
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
}

@keyframes galleryTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.aboutbusinessGallerySwiper .swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.aboutbusinessGallerySwiper img {
  width: 100%;
  height: 20em;
}

.prcard:hover:before {
  opacity: 1;
}
.prcard:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.prcard {
  position: relative;
  padding: 1em;
  /* margin: 10px; */
}
.prcard:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 95%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.prcard img {
  width: 95%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.prcard .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
.prcard .info h1 {
  margin: 0px;
}
.prcard .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.aboutbusinessTestimonialWrap .swiper-slide-active {
  backdrop-filter: blur(3px) saturate(100%);
  -webkit-backdrop-filter: blur(8px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 15px 15px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.businessTestimonialWrap {
  background: rgb(143, 184, 208);
  background: linear-gradient(
    90deg,
    rgba(143, 184, 208, 1) 50%,
    rgba(248, 168, 140, 1) 50%
  );

  position: relative;
  z-index: 2;
  padding: 50px 0;
}

.aboutbusinessTestimonialWrap {
  margin-top: 10px;
}

.aboutFpoTestimonialWrap .swiper-slide-active {
  backdrop-filter: blur(3px) saturate(100%);
  -webkit-backdrop-filter: blur(3px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 15px 15px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.sec-title h2 {
  font-size: 60px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  letter-spacing: 0px;
  text-transform: uppercase;
}
.sec-title p {
  font-size: 18px;
  line-height: 28px;
}
.aboutBusinessTestimonialArea {
  background: rgb(143, 184, 208);
  background: linear-gradient(
    90deg,
    rgba(143, 184, 208, 1) 50%,
    rgba(248, 168, 140, 1) 50%
  );
  position: relative;
  z-index: 2;
  padding: 50px 0;
}
.aboutBusinessTestimonialCard {
  border: 7px solid #fff;
  text-align: center;
  border-radius: 45px;
  position: relative;
  z-index: 2;
}
.aboutBusinessTestimonialCard p {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding: 50px;
  padding-bottom: 30px;
  position: relative;
  z-index: 3;
}

.client-video {
  padding-right: 15px;
}
.client-info {
  position: relative;
  z-index: 3;
}
.client-info a {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  font-size: 22px;
}
.client-info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 50px;
}
.client-info h6 {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.client-info span {
  display: inline-block;
  color: #fff;
  font-size: 12px;
}
