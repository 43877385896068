@import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap');
.brd {
  /* border: 1px solid red; */
}
.myfpo-home {
  padding-right: 5px;
}

.homeItemWrap {
  transition: 0.5s ease;
}
.homeItemWrap:hover {
  transform: scale(1.1);
}
.fpo-icon-img {
  border-radius: 50px 50px;
  border: 1px solid black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}

.myfpo-broadcast-btn {
  width: 250px;
  font-weight: bolder;
  border-radius: 50px 50px;
  background-color: #ffc107 !important;
  border-radius: 25px 25px !important;
  color: black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}

.notificationItem:hover {
  backdrop-filter: blur(15px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}

.notificationItem .MuiTypography-colorTextSecondary {
  color: white;
}
