.fpo-content-wrap {
  padding: 0px 5px;
}

.fpo-cards-wrap {
  padding: 8px;
}

.fpo-cards {
  width: 170px;
  height: 170px;
  margin: 10px;
  border-radius: 20px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  /* border: 1px solid black; */
}

.fpo-broadcast-btn {
  width: 550px;
  margin: 10px;
  font-size: 26px;
  font-weight: bolder;
  border-radius: 50px 50px;
}

.content-wrapper-services {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 425px) {
  .resp-ServiceNav {
    display: none !important;
  }
}

/* @media only screen and (max-width:768px) {
    .fpo-content-wrap {
        width: calc(100% - 57px);
    }
} */
