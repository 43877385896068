@import url(https://fonts.googleapis.com/css2?family=Orbitron:display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind+Madurai:display=swap);
@import url(https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Nunito', sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

html {
  scroll-behavior: smooth;
}

.fixit {
  /* position: relative;
  height: 50px; */
}

body::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  /* border-radius: 10px; */
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 122, 8);
}

.brd {
  border: 1px solid red;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1020;
}

.nav-wrap .MuiSvgIcon-root {
  font-size: 2rem !important;
  color: #fff !important;
}

.green-stripe {
  background-repeat: 'no-repeat';
  background-size: 'cover';
}
.wrapper {
  display: flex;
  justify-content: space-between;
  /* box-shadow: inset 0px 0px 10px 0px #ababab;
  -webkit-box-shadow: inset 0px 0px 10px 0px #ababab;
  -moz-box-shadow: inset 0px 0px 10px 0px #ababab;
  -o-box-shadow: inset 0px 0px 10px 0px #ababab; */
}

.header-top {
  background-color: rgb(255, 255, 255);
  height: 150.5px;
  padding: 0;
  margin: 0;
}
.feed-logo {
  height: inherit;
  padding: 0px 5px 0px 5px;
}

.feed-logo-img {
  height: inherit;
  /* padding: 10px; */
  margin-bottom: 3px;
  /* border-radius: 0px 0px 30px 30px; */
  /* box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75); */
}

.nav-wrap {
  height: inherit;
}
.orange-stripe {
  height: 33.3%;
  border-radius: 5px 5px;
}

.white-bg {
  height: 33.3%;
}

.green-stripe {
  height: 33.3%;
  border-radius: 5px 5px;
}

.topnav .search-container {
  float: right;
}

.topnav input[type='text'] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

.header-social-icons a {
  margin: 2px;
}

.lng-select {
  height: auto;
  padding: 0px !important;
}
.search-bar input {
  border: none;
  border-radius: 5px 5px;
}

.download-btn {
  height: auto;
  padding: 1px !important;
}

.title {
  color: red;
  font-size: 22px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  word-spacing: 0.5px;
  /* text-shadow: 0px 0px 2px #CE5937; */
  /* text-shadow: 1px 0px 0 #f3a42b, 1px 0px 0 #f3a42b; */
}
.header-social-icons a img {
  border-radius: 50px 50px;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
}

.navbar-toggler {
  background-color: white !important;
}
.navbar-toggler-icon {
  color: black !important;
}

.nav-link {
  padding: 0rem !important;
}
.nav-list ul li {
  margin: 0px 8px 0px;
}

.nav-list ul li:hover {
  transition: 0.6s;
}

.nav-list ul li a {
  color: WHITE;
  font-size: 16px !important;
}

.nav-list ul li a:hover {
  background-color: white;
  color: black;
  transition: 0.4s;
  border-radius: 5px 5px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}
.dropdown-menu {
  background-color: white;
}

.dropdown-item {
  color: black !important;
}

/* .login-btn {
  background-image: linear-gradient(
    to right,
    #ff8008 0%,
    #ffc837 51%,
    #ff8008 100%
  );
} */
.login-btn {
  margin: 2px;
  padding: 1px 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  /* color: white; */
  /* border: 2px solid white; */
  border: none;
  border-radius: 5px;
  display: block;
  -webkit-transform: scaleZ(1.5);
          transform: scaleZ(1.5);
  /* background-image: linear-gradient(
    to right,
    #ff8008 0%,
    #ffc837 51%,
    #ff8008 100%
  ); */
  background-color: #fafafa;
}

/* .login-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
} */

/* .register-btn {
  background-image: linear-gradient(
    to right,
    #e44d26 0%,
    #f16529 51%,
    #e44d26 100%
  );
} */
.register-btn {
  margin: 2px;
  padding: 1px 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  background-color: #dadad4;
  /* color: white; */
  border-radius: 5px;
  border: none;
  /* border: 2px solid white; */
  display: block;
}

/* .register-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
} */

.resp-logo-title {
  display: flex;
  justify-content: space-around;
}

/* Media Queries */
@media only screen and (max-width: 525px) {
  .sticky-header {
    position: relative !important;
  }
  .feed-logo {
    display: none;
  }

  .white-bg {
    height: auto;
  }
  .orange-stripe {
    height: auto;
    border-bottom-left-radius: 0rem !important;
  }

  .green-stripe {
    height: auto;
    padding: 2px;
    border-top-left-radius: 0rem !important;
  }

  .contact-num {
    display: none;
  }

  .contact-mail {
    display: none;
  }

  .search-bar {
    display: none;
  }

  .header-social-icons a {
    margin: 1px;
  }

  .header-social-icons a img {
    width: 15px;
    border-radius: 50px 50px;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  }

  .nav-list ul li {
    border: none;
  }

  .title {
    font-size: 0.85em !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    word-spacing: 0.5px;
  }
  /* .reg-no {
    font-size: 6px;
  } */
  .rightAlign {
    text-align: right !important;
  }
  select option {
    font-size: 12px !important;
  }

  .download-btn {
    padding: 0rem !important;
  }

  .header-top {
    height: auto;
  }
}
@media only screen and (min-width: 525px) and (max-width: 769px) {
  .sticky-header {
    position: relative !important;
  }
  .orange-stripe {
    height: auto;
    border-bottom-left-radius: 0rem !important;
  }
  .white-bg {
    height: auto;
  }

  .green-stripe {
    height: auto;
    padding: 2px;
    border-top-left-radius: 0rem !important;
  }
  .feed-logo {
    display: none;
  }

  .header-top {
    height: auto;
  }
  .title {
    font-size: 18px;
  }
  .nav-list ul li {
    border: none;
  }

  .reg-no {
    font-size: 12px;
  }

  .contact-num {
    display: none;
  }

  .contact-mail {
    display: none;
  }

  .search-bar {
    display: none;
  }

  .header-social-icons a img {
    width: 18px;
    border-radius: 50px 50px;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .sticky-header {
    position: relative !important;
  }
  .resp-mobile-logo {
    display: none;
  }
  .orange-stripe {
    height: 33.3%;
    border-bottom-left-radius: 0rem !important;
  }
  .white-bg {
    height: 43.3%;
  }

  .green-stripe {
    height: 23.3%;
    padding: 5px;
    border-top-left-radius: 0rem !important;
  }
  .contact-num {
    display: none;
  }
  .contact-mail {
    display: none;
  }
  .search-bar {
    display: none;
  }
  .title {
    font-size: 14px;
  }
  .reg-no {
    font-size: 12px;
  }
  .nav-list ul li {
    margin: 0px 5px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1250px) {
  .orange-stripe {
    height: 26.3%;
  }
  .white-bg {
    height: 40.3%;
  }

  .green-stripe {
    height: 33.3%;
    padding: 2px;
  }
  .contact-num {
    font-size: 12px;
  }
  .contact-num img {
    width: 20px;
  }
  .contact-mail {
    font-size: 12px;
  }
  .contact-mail img {
    width: 20px;
  }
  .title {
    font-size: 14px;
    margin-bottom: 0rem !important;
  }

  .reg-no {
    font-size: 12px !important;
    margin-bottom: 0rem !important;
  }
  .nav-list ul li {
    margin: 0px 5px 0px;
  }
  .nav-list ul li a {
    font-size: 10px !important;
  }
  .header-social-icons img {
    width: 15px;
  }
}
@media only screen and (min-width: 1200px) {
  .orange-stripe {
    height: 26.3%;
  }
  .white-bg {
    height: 44.3%;
  }

  .green-stripe {
    height: 29.3%;
    padding: 2px;
  }
  .title {
    margin-bottom: 0rem !important;
  }
}

.brd {
  border: 1px solid red;
}

.swiper-button-next:after {
  /* content: "f0a9" !important; */
  /* background-image: url(public/assets/common/next.png); */
  /* background-image: URL("${process.env.PUBLIC_URL}/assets/common/next.png"); */
  /* color: red; */
}
/* carousel section */
.carousel-section {
  /* position: relative; */
  /* top: 240px; */
}
.carousel-inner {
  height: 565px;
  /* border-radius: 0px 0px 20px 20px; */
}

.carousel-section .carousel-inner img {
  height: 100% !important;
  -webkit-animation: thing 20s;
          animation: thing 20s;
}

@-webkit-keyframes thing {
  from {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes thing {
  from {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

/* How FEED Works */
.section-heading {
  margin: 10px;
  padding: 2px;
  background-color: orange;
  color: white;
  border-radius: 10px 10px;
}

.oceanWrap {
  /* position:relative;
  text-align:center; */
  /* height: 400px; */
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
  color: white;
  /* border: 1px solid red; */
  height: 100px;
  /* border-radius: 25px 25px; */
}

.waves {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 150px;
  max-height: 150px;
}

/* Animation */

.parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
          animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}
@-webkit-keyframes move-forever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
            transform: translate3d(-90px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
            transform: translate3d(85px, 0, 0);
  }
}
@keyframes move-forever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
            transform: translate3d(-90px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
            transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}

.shipmove {
  -webkit-animation: shipmove 25s linear infinite;
          animation: shipmove 25s linear infinite;
}

@-webkit-keyframes shipmove {
  0%,
  100% {
    -webkit-transform: translateX(20%) rotate(-1deg) rotateX(5deg);
            transform: translateX(20%) rotate(-1deg) rotateX(5deg);
  }
  100% {
    -webkit-transform: translateX(900px);
            transform: translateX(900px);
  }
}

@keyframes shipmove {
  0%,
  100% {
    -webkit-transform: translateX(20%) rotate(-1deg) rotateX(5deg);
            transform: translateX(20%) rotate(-1deg) rotateX(5deg);
  }
  100% {
    -webkit-transform: translateX(900px);
            transform: translateX(900px);
  }
}

/* Media Queries */

@media only screen and (max-width: 426px) {
  /* .carousel-section {
    display: none !important;
  } */

  .resp-events-view {
    display: none;
  }
}
@media only screen and (min-width: 424px) and (max-width: 768px) {
  /* carousel section */
  /* .carousel-inner {
    height: 350px;
  }

  .carousel-section .carousel-inner img {
    height: 100% !important;
    animation: thing 20s;
  } */
}

/* .events-section {
  height: 590px;
  border:1px solid red;
} */


/* .brd {
    border: 1px solid red;
} */
.feed-services-section {
  margin-top: 20px;
  margin-bottom: 40px;
}

.feed-services-section .nav-link {
  padding: 0rem !important;
}
.card-img img {
  position: relative;
  z-index: 10;
  top: -55px;
  padding: 3px;
  border-radius: 50% 50%;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
}

.services-card {
  height: 200px;
  max-height: 200px;
  margin: 2 auto !important;
  border-radius: 10px 10px 10px 10px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.services-card img {
  width: 65px;
  height: 65px;
}

.services-card .services-card-header {
  height: 50px;
  border-radius: 10px 10px 50% 50%;
}

.card-body {
  padding: 0rem !important;
}
.services-card .card-body h5 {
  position: relative;
  height: 10px;
  top: -30px;
  font-weight: bold;
}

.wrapper {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
}

.item-flx {
  width: 100%;
}

@media only screen and (max-width: 426px) {
  .feed-services-section {
    margin-top: 2px;
    margin-bottom: 40px;
  }

  /* .feed-service-img {
        display: none !important;
    } */
  .rocket-ant {
    display: none !important;
  }

  .feed-services-section .nav-link {
    padding: 0rem !important;
  }
  .card-img img {
    position: relative;
    z-index: 10;
    top: -40px;
    width: 50 !important;
    padding: 5px;
    border-radius: 50% 50%;
    background-color: white;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  }

  .services-card {
    width: 80px;
    height: 80px;
    border-radius: 10px 10px 10px 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .item-flx {
    width: 90px;
    height: 90px;
  }

  .services-card img {
    margin: 5px;
    width: 40px;
    height: 40px;
  }

  .services-card .services-card-header {
    height: 30px;
    border-radius: 10px 10px 50% 50%;
  }
  .services-card .card-body {
    padding: 15px;
  }

  .services-card .card-body h5 {
    position: relative;
    /* height: 10px; */
    top: -30px;
    font-size: 10px;
    /* font-weight: bold; */
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.listing-carousel-button {
  position: absolute;
  /* top: 50%; */
  width: 50px;
  /* height: 50px; */
  line-height: 50px;
  margin-top: -25px;
  z-index: 100;
  cursor: pointer;
  background: #007aff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.45);
  transition: all 200ms linear;
  outline: none;
}
.listing-carousel-button.listing-carousel-button-next {
  right: -30px;
  padding-right: 20px;
  border-radius: 60px 0 0 60px;
}
.listing-carousel-button.listing-carousel-button-prev {
  left: -30px;
  padding-left: 20px;
  border-radius: 0 60px 60px 0;
}
.listing-carousel-button.listing-carousel-button-next:hover {
  right: -15px;
  background: rgba(6, 27, 65, 0.4);
}
.listing-carousel-button.listing-carousel-button-prev:hover {
  left: -15px;
  background: rgba(6, 27, 65, 0.4);
}
.testimonialCard {
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0.9;
  /* min-height: 20em; */
}
.testimonials-text {
  padding: 15px 10px 15px;
  overflow: hidden;
  /* background: #f5f6fa; */
  background-color: #1f978e;
  /* border:1px solid #f1f1f1; */
  transition: all 0.3s ease-in-out;
}
.testimonials-text-after {
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  color: #ccc;
  opacity: 0.3;
  font-size: 35px;
  transition: all 400ms linear;
  bottom: 25px;
  right: 30px;
}
.testimonials-text-before {
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  color: #ccc;
  opacity: 0.3;
  font-size: 35px;
  transition: all 400ms linear;
  top: 25px;
  left: 30px;
}
.testimonials-text .listing-rating {
  float: none;
  display: inline-block;
  margin-bottom: 12px;
}
.listing-rating i {
  color: #007aff;
}
.testimonials-avatar h3 {
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 0px;
}
.testimonialProfession {
  font-weight: 400;
  font-size: 12px;
  padding-top: 6px;
  color: white;
  text-transform: capitalize;
}

.testimonilaAvatar {
  position: absolute;
  left: 50%;
  /* top: -30px; */
  /* width: 90px; */
  /* height: 90px; */
  padding-top: 5px;
  margin-left: -45px;
  z-index: 20;
}
.testimonilaAvatar img {
  /* width: 90px;
	height: 90px; */
  float: left;
  border-radius: 100%;
  background-color: white;
  border: 6px solid #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.1);
}

.testimonials-text p {
  color: white;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  padding-bottom: 10px;
  font-weight: 500;
}
.text-link {
  position: absolute;
  bottom: 0;
  padding: 15px 0;
  text-align: center;
  border-radius: 10px 10px 0 0;
  background: #f9f9f9;
  border: 1px solid #eee;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  left: 50%;
  width: 200px;
  margin-left: -100px;
}

.testimonialCard {
  height: 250px;
}
.swiper-slide-active .testimonialCard {
  height: 100%;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
.swiper-slide-active .testimonials-text {
  border-radius: 20px;
}

.swiper-slide-active .testimonialCard .testimonialMessage {
  padding: 15px;
}

.swiper-slide-prev .testimonialCard {
  opacity: 0.4;
}

.swiper-slide-next .testimonialCard {
  opacity: 0.4;
}

.testimonials-carousel-wrap {
  /* height: 400px; */
  padding: 10px;
}
.testimonials-carousel-wrap .swiper-button-prev:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f359';
  font-size: 40px;
  color: white;
  border-radius: 50%;
  background-color: black;
}

.testimonials-carousel-wrap .swiper-button-next:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f35a';
  font-size: 40px;
  color: white;
  border-radius: 50%;
  background-color: black;
}

.feedWorkingHeading {
  font-size: 32px;
  font-family: 'Orbitron', sans-serif;
  margin: '50px 0px';
}

@media only screen and (max-width: 525px) {
  .video-wrap iframe {
    height: 250px;
  }
}

.partnersNamePlate {
  font-family: 'Hind Madurai', sans-serif;
  font-size: 32px;
  letter-spacing: 0.1em; /* Adjust as needed */
}


.newsletter-section {
  margin: 0 auto;
  bottom: -30px;
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 15px 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 426px) {
  .newsletter-section-wrap {
    display: none;
  }
}

.regFormHeading {
  /* background: #498554;
background: -webkit-linear-gradient(to right, #498554 0%, #92e49e 100%);
background: -moz-linear-gradient(to right, #498554 0%, #92e49e 100%);
background: linear-gradient(to right, #498554 0%, #92e49e 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent; */
  /* background-color: rgb(211, 209, 82);
color: white;
text-align: center;
padding-bottom: 5px;
margin-bottom: 10px; */
}

.formDetailsWrap {
  /* padding: 8px; */
  /* border-radius: 15px 15px; */
  /* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); */
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
}

/* 

span {
  font-size: 12px;
}

.lrContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 20px 20px;
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 580px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
  text-align: center;
}

.lrForm-reg-btn {
  border-radius: 20px;
  border: 1px solid #18608a;
  background-color: #18608a;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.lrForm-lgn-btn {
  border: 3px solid rgb(250, 187, 250);
  border-radius: 20px;
  background-color: #ff805d;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
    
.lrForm-gtl-btn {background-image: linear-gradient(to right, #c21500 0%, #ffc500  51%, #c21500  100%)}
.lrForm-gtl-btn {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
}

.lrForm-gtl-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
  
.lrForm-gtr-btn {background-image: linear-gradient(to right, #00c6ff 0%, #0072ff  51%, #00c6ff  100%)}
.lrForm-gtr-btn {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
}

.lrForm-gtr-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
 
input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
  
.lrForm-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.lrContainer.right-panel-active .sign-in-container {
  transform: translateX(100%);
}
  
.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.lrContainer.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}
  
@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.lrContainer.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.lrContainer.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.lrContainer.right-panel-active .overlay-left {
  transform: translateX(0);
  background-color: rgb(241, 161, 94);
}

.overlay-right {
  right: 0;
  background-color: rgb(240, 181, 252);
  transform: translateX(0);
}

.lrContainer.right-panel-active .overlay-right {
  transform: translateX(20%);
}
 */

.lg-form-wrap {
  width: calc(100vw - 100px);
  /* height: 600px; */
  margin: 8px auto 8px;
  /* border: 1px solid red; */
  padding: 10px;
  border-radius: 25px 25px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.lrForm-lgn-btn {
  border: none;
  /* border: 3px solid rgb(250, 187, 250); */
  border-radius: 20px;
  background-color: #ff805d;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

/* Footer Section */
.footer-section {
  padding: 20px;
  background-color: #f37835;
  color: white;
  /* height: 300px; */
}

.footer-section-newsletter {
  margin: auto;
  /* top: -100px; */
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 15px 15px;
  /* height: 200px; */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.section-separator::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 3px;
  width: 50px;
  border-radius: 3px;
  z-index: 2;
  /* background-color: #007aff; */
  border-bottom: 1px solid white;
  margin-left: -25px;
}
.footerTitleBtmBrd {
  border-width: 0 0 2px;
  border-style: solid;
}

.footer-section-links ul {
  padding: 0rem !important;
}

.footer-section-links ul li {
  list-style-type: none;
}

.footer-section-links ul li a {
  padding: 0rem !important;
  text-decoration: none;
  color: white !important;
  font-size: 14px;
}

.conditions-row {
  border-top: 1px solid white;
}

/* Media Queries */
@media only screen and (max-width: 426px) {
  .links-row {
    display: none;
  }
  .conditions-row {
    border: none;
  }
}

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.banner-right {
  -webkit-animation-name: float-bob;
          animation-name: float-bob;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

/* body {
  background: #fbfbfd;
} */

.new_footer_area {
  background: #fbfbfd;
  margin-top: 25px;
}

.new_footer_top {
  padding: 100px 0px 270px;
  position: relative;
  overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}
.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}
.btn_get:hover {
  color: #fff;
  background: #6754e2;
  border-color: #6754e2;
  box-shadow: none;
}
a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  /* color: #5e2ced; */
  color: rgb(230, 81, 13);
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: '\e741';
}
.ti-twitter-alt:before {
  content: '\e74b';
}
.ti-vimeo-alt:before {
  content: '\e74a';
}
.ti-pinterest:before {
  content: '\e731';
}

.btn_get_two {
  box-shadow: none;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
.t_color {
  color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url('http://droitthemes.com/html/saasland/img/seo/footer_bg.png')
    no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  /* background: url("https://1.bp.blogspot.com/-mvKUJFGEc-k/XclCOUSvCnI/AAAAAAAAUAE/jnBSf6Fe5_8tjjlKrunLBXwceSNvPcp3wCLcBGAsYHQ/s1600/volks.gif") no-repeat center center; */
  /* background-image: url("../assets/my-market/bullock-cart.png"); */
  /* width: 330px; */
  height: 105px;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 60s linear infinite;
  animation: myfirst 60s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url('https://1.bp.blogspot.com/-hjgfxUW1o1g/Xck--XOdlxI/AAAAAAAAT_4/JWYFJl83usgRFMvRfoKkSDGd--_Sv04UQCLcBGAsYHQ/s1600/cyclist.gif')
    no-repeat center center;
  width: 88px;
  height: 100px;
  background-size: 100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 45s linear infinite;
  animation: myfirst 45s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_three {
  height: 105px;
  background-size: 100%;
  position: absolute;
  /* bottom: 0; */
  left: 30%;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

/*************footer End*****************/

.myfarmmain::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
.myfarmmain::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

/* Handle */
.myfarmmain::-webkit-scrollbar-thumb {
  background: green;
  border-radius: 10px;
}

/* Handle on hover */
.myfarmmain::-webkit-scrollbar-thumb:hover {
  background: green;
}

@media only screen and (max-width: 768px) {
  .my-farm-wrap {
    min-height: 782px !important;
  }
}

.brd {
  border: 1px solid red;
}
.servicenav-wrap {
  /* box-shadow: 0px 0px 5px 0px rgba(126, 126, 126, 0.25) inset; */
  /* border-radius: 20px 20px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75); */
  margin: 10px auto;
}
.business-btn {
  background-color: #6739b7;
  color: white;
}
.farm-btn {
  background-color: #a4cf3e;
  color: white;
}

.export-btn {
  background-color: #64c5bc;
  color: white;
}

.wheat-btn {
  background-color: #dd8444;
  color: white;
}

.merun-btn {
  background-color: rgb(156, 57, 39);
}

.nav-btn .nav-link {
  padding: 0rem !important;
}

@media only screen and (max-width: 425px) {
  .resp-ServiceNav {
    display: none;
  }
}

.side-navbar {
  /* box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55); */
  /* border-radius: 5px 5px; */
  /* border-right: 1px solid #f6f4f5; */
  /* border-top-right-radius: 5px; */
}

.side-navbar ~ div {
  overflow-x: hidden;
}

@media only screen and (max-width: 769px) {
  .side-navbar ~ div {
    position: relative;
    left: 57px;
    min-height: 606px;
    width: calc(100% - 57px);
  }

  .side-navbar {
    position: absolute;
  }
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.my-farm-list .list-item {
  margin-bottom: 2em;
}

.my-farm-list .list-avatar {
  width: 6em;
  height: 6em;
}

@media only screen and (max-width: 768px) {
  .my-farm-list .list-avatar {
    width: 4em;
    height: 4em;
  }
}



.my-farm-home .grass-logo {
  height: 13em;
}

.my-farm-home .ant-logo {
  height: 20em;
}

.feedConnect {
  background-color: #a4cf3e !important;
}

.my-farm-home {
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .side-navbar {
    position: absolute !important;
  }

  .my-farm-home .image-grid {
    display: none;
  }

  .my-farm-wrap {
    position: relative !important;
    left: 0px !important;
    margin-left: 57px;
  }

  .my-farm-home .nav-link img {
    width: 7em;
    height: 7em;
  }

  .my-farm-home {
    margin-top: 0;
  }

  .feedConnect {
    margin-top: 2em !important;
  }
}




.seasonOneWrap {
  text-align: justify;
  text-justify: inter-word;
}

.seasonTwoWrap {
  text-align: justify;
  text-justify: inter-word;
}

.seasonthreeWrap {
  text-align: justify;
  text-justify: inter-word;
}

.mainOl {
  margin: 0;
  list-style: none;
  padding: 0;
  --hue: 1;
  --unit: 1rem;
}
.event-date {
  margin: 0 0 0.25rem;
  font-weight: bold;
}
.event-description {
  margin: 0;
}
.timelineCard {
  --height: 7rem;
  position: relative;
  display: block;
  background-color: hsl(calc(var(--hue) * 360 / 20), 90%, 65%);
  border-color: hsl(calc(var(--hue) * 360 / 20), 90%, 65%);
  padding: 1rem;
  margin: 2rem 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px 25px;
}
.timelineCard::before {
  content: '';
  background-color: inherit;
  position: absolute;
  display: block;
  width: var(--unit);
  height: var(--unit);
  top: 100%;
  left: calc(50% - (var(--unit) / 2));
}
.timelineCard::after {
  content: '';
  position: absolute;
  display: block;
  top: calc(100% + var(--unit));
  left: calc(50% - (var(--unit)));
  border: var(--unit) solid transparent;
  border-top-color: inherit;
}
.timelineCard:last-child::before,
.timelineCard:last-child::after {
  content: none;
}
.timelineCard:nth-child(20n + 1) {
  --hue: 1;
}
.timelineCard:nth-child(20n + 2) {
  --hue: 2;
}
.timelineCard:nth-child(20n + 3) {
  --hue: 3;
}
.timelineCard:nth-child(20n + 4) {
  --hue: 4;
}
.timelineCard:nth-child(20n + 5) {
  --hue: 5;
}
.timelineCard:nth-child(20n + 6) {
  --hue: 6;
}
.timelineCard:nth-child(20n + 7) {
  --hue: 7;
}
.timelineCard:nth-child(20n + 8) {
  --hue: 8;
}
.timelineCard:nth-child(20n + 9) {
  --hue: 9;
}
.timelineCard:nth-child(20n + 10) {
  --hue: 10;
}
.timelineCard:nth-child(20n + 11) {
  --hue: 11;
}
.timelineCard:nth-child(20n + 12) {
  --hue: 12;
}
.timelineCard:nth-child(20n + 13) {
  --hue: 13;
}
.timelineCard:nth-child(20n + 14) {
  --hue: 14;
}
.timelineCard:nth-child(20n + 15) {
  --hue: 15;
}
.timelineCard:nth-child(20n + 16) {
  --hue: 16;
}
.timelineCard:nth-child(20n + 17) {
  --hue: 17;
}
.timelineCard:nth-child(20n + 18) {
  --hue: 18;
}
.timelineCard:nth-child(20n + 19) {
  --hue: 19;
}
.timelineCard:nth-child(20n + 20) {
  --hue: 20;
}
@media (min-width: 550px) and (max-width: 899px) {
  .timelineCard {
    margin: 1rem;
    width: calc(50% - 4rem);
    float: left;
    min-height: var(--height);
  }
  .timelineCard:nth-child(4n + 3),
  .timelineCard:nth-child(4n + 4) {
    float: right;
  }
  .timelineCard:nth-child(4n + 1)::before {
    top: calc(var(--height) / 2 + var(--unit) / 2);
    left: 100%;
  }
  .timelineCard:nth-child(4n + 1)::after {
    top: calc(var(--height) / 2);
    left: calc(100% + (var(--unit)));
    border: var(--unit) solid transparent;
    border-left-color: inherit;
  }
  .timelineCard:nth-child(4n + 3)::before {
    top: calc(var(--height) / 2 + var(--unit) / 2);
    left: -1rem;
  }
  .timelineCard:nth-child(4n + 3)::after {
    top: calc(var(--height) / 2);
    left: -3rem;
    border: var(--unit) solid transparent;
    border-right-color: inherit;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  .timelineCard {
    margin: 1rem;
    width: calc(33.33% - 4rem);
    float: left;
    min-height: 7rem;
  }
  .timelineCard:nth-child(6n + 4),
  .timelineCard:nth-child(6n + 5),
  .timelineCard:nth-child(6n + 6) {
    float: right;
  }
  .timelineCard:nth-child(6n + 1)::before,
  .timelineCard:nth-child(6n + 2)::before {
    top: calc(var(--height) / 2 + var(--unit) / 2);
    left: 100%;
  }
  .timelineCard:nth-child(6n + 1)::after,
  .timelineCard:nth-child(6n + 2)::after {
    top: 3.5rem;
    left: calc(100% + (var(--unit)));
    border: var(--unit) solid transparent;
    border-left-color: inherit;
  }
  .timelineCard:nth-child(6n + 4)::before,
  .timelineCard:nth-child(6n + 5)::before {
    top: calc(var(--height) / 2 + var(--unit) / 2);
    left: -1rem;
  }
  .timelineCard:nth-child(6n + 4)::after,
  .timelineCard:nth-child(6n + 5)::after {
    top: calc(var(--height) / 2);
    left: -3rem;
    border: var(--unit) solid transparent;
    border-right-color: inherit;
  }
}
@media (min-width: 1200px) {
  .mainOl {
    max-width: 1600px;
    margin: 0 auto;
  }
  .timelineCard {
    margin: 1rem;
    width: calc(25% - 4rem);
    float: left;
    min-height: 7rem;
  }
  .timelineCard:nth-child(8n + 5),
  .timelineCard:nth-child(8n + 6),
  .timelineCard:nth-child(8n + 7),
  .timelineCard:nth-child(8n + 8) {
    float: right;
  }
  .timelineCard:nth-child(8n + 1)::before,
  .timelineCard:nth-child(8n + 2)::before,
  .timelineCard:nth-child(8n + 3)::before {
    top: calc(var(--height) / 2 + var(--unit) / 2);
    left: 100%;
  }
  .timelineCard:nth-child(8n + 1)::after,
  .timelineCard:nth-child(8n + 2)::after,
  .timelineCard:nth-child(8n + 3)::after {
    top: calc(var(--height) / 2);
    left: calc(100% + (var(--unit)));
    border: var(--unit) solid transparent;
    border-left-color: inherit;
  }
  .timelineCard:nth-child(8n + 5)::before,
  .timelineCard:nth-child(8n + 6)::before,
  .timelineCard:nth-child(8n + 7)::before {
    top: calc(var(--height) / 2 + var(--unit) / 2);
    left: -1rem;
  }
  .timelineCard:nth-child(8n + 5)::after,
  .timelineCard:nth-child(8n + 6)::after,
  .timelineCard:nth-child(8n + 7)::after {
    top: calc(var(--height) / 2);
    left: -3rem;
    border: var(--unit) solid transparent;
    border-right-color: inherit;
  }
}


.brd {
  /* border: 1px solid red; */
}
.myfpo-home {
  padding-right: 5px;
}

.homeItemWrap {
  transition: 0.5s ease;
}
.homeItemWrap:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.fpo-icon-img {
  border-radius: 50px 50px;
  border: 1px solid black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}

.myfpo-broadcast-btn {
  width: 250px;
  font-weight: bolder;
  border-radius: 50px 50px;
  background-color: #ffc107 !important;
  border-radius: 25px 25px !important;
  color: black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}

.notificationItem:hover {
  backdrop-filter: blur(15px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}

.notificationItem .MuiTypography-colorTextSecondary {
  color: white;
}

.brd {
  border: 1px solid red;
}

.about-business-descpr {
  width: 100%;
  height: 350px;
  padding: 1em;
  overflow: auto;
}
.about-business-content fieldset {
  border: 3px solid black !important;
  border-radius: 15px 15px;
}
.about-business-content fieldset legend {
  text-align: center;
  width: auto !important;
}

.about-business-content p {
  text-align: justify;
  text-justify: inter-word;
}

.about-business-images-wrap {
  margin-top: 6px;
}

.about-business-images {
  display: flex;
  justify-content: space-evenly;
}
.about-business-images img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.about-business-info-card {
  display: flex;
  justify-content: space-around;
  height: 50px;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.about-business-info-card-role {
  display: flex;
  justify-content: space-evenly;
  border-radius: 0px 20px 20px 0px;
}
.info-card-orange {
  background-color: orange;
}

.info-card-green {
  background-color: #9fb43b;
}
.info-card-teal {
  background-color: #2ba4ad;
}
.info-card-musk {
  background-color: #fdbc62;
}
.info-card-red {
  background-color: red;
}

.about-business-info-card-role img {
  border-radius: 10px 10px;
  background-color: white;
  /* padding: 5px; */
}

.aboutFpoGallerySwiper .swiper-slide {
  text-align: -webkit-center;
}

/* .aboutFpoGallerySwiper .swiper-button-prev {
  display: none;
}


.aboutFpoGallerySwiper .swiper-button-next {
  display: none;
} */

.productServicesTitle {
  margin-bottom: 0.5em;
  /* color: orange; */
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
  -webkit-animation: productServicesTitle 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: productServicesTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes productServicesTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes productServicesTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.galleryTitle {
  margin-bottom: 0.5em;
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
  -webkit-animation: galleryTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: galleryTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.licensesTitle {
  color: orange;
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
}

@-webkit-keyframes galleryTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes galleryTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.aboutbusinessGallerySwiper .swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.aboutbusinessGallerySwiper img {
  width: 100%;
  height: 20em;
}

.prcard:hover:before {
  opacity: 1;
}
.prcard:hover .info {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.prcard {
  position: relative;
  padding: 1em;
  /* margin: 10px; */
}
.prcard:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 95%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.prcard img {
  width: 95%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.prcard .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  transition: 0.5s;
}
.prcard .info h1 {
  margin: 0px;
}
.prcard .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.aboutbusinessTestimonialWrap .swiper-slide-active {
  backdrop-filter: blur(3px) saturate(100%);
  -webkit-backdrop-filter: blur(8px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 15px 15px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.businessTestimonialWrap {
  background: rgb(143, 184, 208);
  background: linear-gradient(
    90deg,
    rgba(143, 184, 208, 1) 50%,
    rgba(248, 168, 140, 1) 50%
  );

  position: relative;
  z-index: 2;
  padding: 50px 0;
}

.aboutbusinessTestimonialWrap {
  margin-top: 10px;
}

.aboutFpoTestimonialWrap .swiper-slide-active {
  backdrop-filter: blur(3px) saturate(100%);
  -webkit-backdrop-filter: blur(3px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 15px 15px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.sec-title h2 {
  font-size: 60px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  letter-spacing: 0px;
  text-transform: uppercase;
}
.sec-title p {
  font-size: 18px;
  line-height: 28px;
}
.aboutBusinessTestimonialArea {
  background: rgb(143, 184, 208);
  background: linear-gradient(
    90deg,
    rgba(143, 184, 208, 1) 50%,
    rgba(248, 168, 140, 1) 50%
  );
  position: relative;
  z-index: 2;
  padding: 50px 0;
}
.aboutBusinessTestimonialCard {
  border: 7px solid #fff;
  text-align: center;
  border-radius: 45px;
  position: relative;
  z-index: 2;
}
.aboutBusinessTestimonialCard p {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding: 50px;
  padding-bottom: 30px;
  position: relative;
  z-index: 3;
}

.client-video {
  padding-right: 15px;
}
.client-info {
  position: relative;
  z-index: 3;
}
.client-info a {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  font-size: 22px;
}
.client-info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 50px;
}
.client-info h6 {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.client-info span {
  display: inline-block;
  color: #fff;
  font-size: 12px;
}

.business-account-wrap {
  /* background-color: white; */
}





.headr {
  position: relative;
  height: 300px;
  background: #0b345d;
  /* background-image: linear-gradient(155deg, #002f62 0%, #18496a 50%, #138b89 100%); */
}



.exim-policy-reg {
  /* background-color: rgb(136, 136, 252); */
}
.myexport-broadcast-btn {
  color: white !important;
  background-color: #008dd4 !important;
}





.brd {
  border: 1px solid red;
}


.countryCard {
  width: 220px;
  height: 220px;
  margin: 8px;
  background: #fff;
  border-radius: 15px 15px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* position: relative; */
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.countryCard:hover {
  background-color: #ffd861;
  -webkit-transform: translateY(-5px) scale(1.005) translateZ(0);
          transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.countryCard:hover .overlay {
  -webkit-transform: scale(4) translateZ(0);
          transform: scale(4) translateZ(0);
}

.countryCard:hover p {
  color: var(--text-color-hover);
}

.countryCard:active {
  -webkit-transform: scale(1) translateZ(0);
          transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.countryCard p {
  font-size: 17px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}




li:hover > ul.dropdown-menu {
  display: block;
  width: 100%;
  border-bottom: 2px solid orange;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.categoryBtn {
  width: 100%;
  height: 100%;
  font-size: 600;
  padding: 20px;
}

.categoryCardWrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  place-items: center;
  margin: auto;
  justify-content: space-evenly;
  grid-gap: 1rem;
  padding: 1rem;
  border-radius: 15px 15px;
  /* background: conic-gradient(#98d6f3, #fea2b1, #f7f1fa, #f7f1fa, #98d6f3, #98d6f3); */
  /* background: radial-gradient(circle, rgba(8,35,58,1) 0%, rgba(64,71,133,1) 35%, rgba(177,242,255,1) 100%); */
  /* background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(187,164,164,1) 35%, rgba(255,255,255,1) 100%); */
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(209, 207, 207, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.categoryCard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 0.75rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 250px;
  text-align: center;
}
.categoryCard .categoryCardTitle {
  color: #122b61;
  margin: 0;
  font-weight: 600;
}
.categoryCard .button {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  color: #828282;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 0.85rem 1rem;
  border-color: transparent;
  border-radius: 0.85rem;
  margin-top: auto;
  transition: all 200ms ease-in-out;
}
.categoryCard .button::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: conic-gradient(#98d6f3, #fea2b1, #f7f1fa, #98d6f3);
  -webkit-filter: blur(30px);
          filter: blur(30px);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}
.categoryCard .button:focus {
  outline: none;
  border-color: #122b61;
}
.categoryCard .button:hover,
.categoryCard .button:focus {
  background: rgba(255, 255, 255, 1);
  color: #4f4f4f;
}
.categoryCard .button:hover::before,
.categoryCard .button:focus::before {
  -webkit-filter: blur(20px);
          filter: blur(20px);
}

.product-tumb {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: 150px;
  /* padding: 50px; */
  /* background: #f0f0f0; */
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  padding: 10px;
}

/* .productSpecificationsTable .tr:nth-child(even) {
  background-color: #dddddd;
} */
/* table{
  width: 100%;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
td {
  padding: '8px';
} */
/* .productSpecificationsTable {
  border:'1px solid green'
} */
.productSpecificationsTable tr {
  /* border: 1px solid red; */
}

.productSpecificationsTable td {
  /* width: 50%; */
}
.swiper-container-vertical .swiper-button-next,
.swiper-container-vertical .swiper-button-prev {
  left: 50%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}
.swiper-container-vertical .swiper-button-prev {
  top: 0px;
}
.swiper-container-vertical .swiper-button-next {
  top: auto;
  bottom: 10px;
}

.swiper-container-vertical .swiper-button-next {
  position: absolute;
  z-index: 100;
  bottom: 0px;
}
.swiper-container-vertical .swiper-button-next:after {
  font-size: 24px;
}

.swiper-container-vertical .swiper-button-prev {
  position: absolute;
  z-index: 100;
  top: 0px;
}
.swiper-container-vertical .swiper-button-prev:after {
  font-size: 24px;
}









.fpo-content-wrap {
  padding: 0px 5px;
}

.fpo-cards-wrap {
  padding: 8px;
}

.fpo-cards {
  width: 170px;
  height: 170px;
  margin: 10px;
  border-radius: 20px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  /* border: 1px solid black; */
}

.fpo-broadcast-btn {
  width: 550px;
  margin: 10px;
  font-size: 26px;
  font-weight: bolder;
  border-radius: 50px 50px;
}

.content-wrapper-services {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 425px) {
  .resp-ServiceNav {
    display: none !important;
  }
}

/* @media only screen and (max-width:768px) {
    .fpo-content-wrap {
        width: calc(100% - 57px);
    }
} */

.brd {
  border: 1px solid red;
}

.about-fpo-descpr {
  width: 100%;
  height: 350px;
  /* padding: 1em; */
  overflow: auto;
}
.about-fpo-content fieldset {
  /* border: 3px solid black !important; */
  border-radius: 15px 15px;
}
.about-fpo-content fieldset legend {
  text-align: center;
  width: auto !important;
}

.about-fpo-content p {
  text-align: justify;
  text-justify: inter-word;
}

.about-fpo-images-wrap {
  margin-top: 6px;
}

.about-fpo-images {
  display: flex;
  justify-content: space-evenly;
}
.about-fpo-images img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.about-fpo-info-card {
  display: flex;
  justify-content: space-around;
  height: 50px;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.about-fpo-info-card-role {
  display: flex;
  justify-content: space-evenly;
  border-radius: 0px 20px 20px 0px;
}
.info-card-orange {
  background-color: orange;
}

.info-card-green {
  background-color: #9fb43b;
}
.info-card-teal {
  background-color: #2ba4ad;
}
.info-card-musk {
  background-color: #fdbc62;
}
.info-card-red {
  background-color: red;
}

.about-fpo-info-card-role img {
  border-radius: 10px 10px;
  background-color: white;
  /* padding: 5px; */
}

.aboutFpoGallerySwiper .swiper-slide {
  text-align: -webkit-center;
}

/* .aboutFpoGallerySwiper .swiper-button-prev {
  display: none;
}


.aboutFpoGallerySwiper .swiper-button-next {
  display: none;
} */

.productServicesTitle {
  margin-bottom: 0.5em;
  /* color: orange; */
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
  -webkit-animation: productServicesTitle 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: productServicesTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes productServicesTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes productServicesTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.galleryTitle {
  margin-bottom: 0.5em;
  font-family: 'STIX Two Text', serif;
  -webkit-animation: galleryTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: galleryTitle 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.licensesTitle {
  color: orange;
  text-decoration: underline;
  font-family: 'STIX Two Text', serif;
}

@-webkit-keyframes galleryTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes galleryTitle {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.aboutFpoGallerySwiper .swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.aboutFpoGallerySwiper img {
  width: 100%;
  height: 20em;
}

.prcard:hover:before {
  opacity: 1;
}
.prcard:hover .info {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.prcard {
  position: relative;
  padding: 1em;
  /* margin: 10px; */
}
.prcard:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 95%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.prcard img {
  width: 95%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.prcard .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  transition: 0.5s;
}
.prcard .info h1 {
  margin: 0px;
}
.prcard .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.aboutFpoTestimonialWrap {
  /* border: 1px solid red; */
  margin-top: 10px;
}

.aboutFpoTestimonialWrap .swiper-slide-active {
  backdrop-filter: blur(3px) saturate(100%);
  -webkit-backdrop-filter: blur(3px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 15px 15px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

.sec-title h2 {
  font-size: 60px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: var(--blue);
}
.sec-title p {
  font-size: 18px;
  line-height: 28px;
}
.testimonial-area {
  background: rgb(250, 135, 11);
  background: linear-gradient(
    90deg,
    rgba(250, 135, 11, 1) 50%,
    rgba(168, 160, 160, 1) 50%
  );
  position: relative;
  z-index: 2;
  padding: 50px 0;
}
.fpoTestimonialCard {
  border: 7px solid #fff;
  text-align: center;
  border-radius: 45px;
  position: relative;
  z-index: 2;
}
.fpoTestimonialCard p {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding: 50px;
  padding-bottom: 30px;
  position: relative;
  z-index: 3;
}

.client-video {
  padding-right: 15px;
}
.client-info {
  position: relative;
  z-index: 3;
}
.client-info a {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  font-size: 22px;
}
.client-info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 50px;
}
.client-info h6 {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.client-info span {
  display: inline-block;
  color: #fff;
  font-size: 12px;
}
.sec-title.white-title h2 {
  color: #fff;
}

.ctrWrap {
  padding: 5px;
  margin-top: 2em;
  color: black;
  font-weight: 600;
  backdrop-filter: blur(5px) saturate(100%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
}

.brd {
  /* border: 1px solid red; */
}
/* .myfpo-home {
  padding-right: 5px;
} */

.homeItemWrap {
  transition: 0.5s ease;
}
.homeItemWrap:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.fpo-icon-img {
  border-radius: 50px 50px;
  border: 1px solid black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}

.myfpo-broadcast-btn {
  width: 250px;
  font-weight: bolder;
  border-radius: 50px 50px;
  background-color: #ffc107 !important;
  border-radius: 25px 25px !important;
  color: black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}

/* .notificationItem {
  margin-bottom: 4px;
  padding: 0px 10px 0px 10px;
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
} */
.notificationItem:hover {
  backdrop-filter: blur(15px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}

.notificationItem .MuiTypography-colorTextSecondary {
  color: black;
}

.fpo-account-wrap {
  background-color: #f8ffbe;
  /* border: 1px solid red; */
}



.statutory-compliance-wrap {
  background-color: #1f212d;
}

.legal-compliance-wrap {
  background-color: black;
}


.purchaseTable tr th {
  padding: 5px 5px;
  font-weight: normal;
  text-align: center;
}
.purchaseTable .MuiOutlinedInput-inputMarginDense {
  width: auto;
}


@-webkit-keyframes rotate {
  0%,
  50% {
    /* Keep the element at 12 o'clock until halfway through animation. */
    /* transform: rotate(0) translateY(-197px) translateX(-29px) rotate(0deg); */
    -webkit-transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
            transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
  }
}

@keyframes rotate {
  0%,
  50% {
    /* Keep the element at 12 o'clock until halfway through animation. */
    /* transform: rotate(0) translateY(-197px) translateX(-29px) rotate(0deg); */
    -webkit-transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
            transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
  }
}

#centre {
  /* Position circles in the centre of the viewport. */
  /* position: fixed;
  left: 50%;
  top: 50%; */

  /* IE 10 bug: force hardware acceleration to prevent edge pixels
     being left behind on animation. */
  /* transform: rotateZ(0.01deg); */

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.circle {
  /* background: red; */
  border-radius: 50%;
  position: absolute;
}

/* .circle img {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
} */

.middle {
  height: 200px;
  width: 200px;
  left: -100px;
  top: -100px;
  background-image: 'URL("${process.env.PUBLIC_URL}/assets/my-dashboard/quickmenu.png")';
}

.outer {
  /* Note: first half of animation duration is spent at 12 o'clock. */
  -webkit-animation: rotate 2s ease-out 1;
          animation: rotate 2s ease-out 1;
  /* height: 60px;
  width: 60px; */
  left: -30px; /* top/left equal to radius. */
  top: -30px;
}

.trancircle {
  width: 100px;
  height: 100px;
  border: 6px solid coral;
  border-radius: 50% 50%;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
}

.at-10-oclock {
  /* transform: rotate(-36deg) translateY(-210px) translateX(-37px) rotate(-8deg); */
  -webkit-transform: rotate(-36deg) translateY(-19px) translateX(-113px) rotate(35deg);
          transform: rotate(-36deg) translateY(-19px) translateX(-113px) rotate(35deg);
}

.at-12-oclock {
  -webkit-transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
          transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
  z-index: 2;
}

.at-2-oclock {
  /* transform: rotate(45deg) translateY(-7px) translateX(10px) rotate(-47deg); */
  -webkit-transform: rotate(45deg) translateY(-56px) translateX(125px) rotate(-47deg);
          transform: rotate(45deg) translateY(-56px) translateX(125px) rotate(-47deg);
  z-index: 1; /* Make this slide out from between 12 and 4 o'clock. */
}

.at-4-oclock {
  /* transform: rotate(90deg) translateY(-144px) translateX(-28px) rotate(0deg); */
  -webkit-transform: rotate(90deg) translateY(-175px) translateX(158px) rotate(-90deg);
          transform: rotate(90deg) translateY(-175px) translateX(158px) rotate(-90deg);
}

.at-5-oclock {
  -webkit-transform: rotate(135deg) translateY(-284px) translateX(97px) rotate(-133deg);
          transform: rotate(135deg) translateY(-284px) translateX(97px) rotate(-133deg);
}

.at-6-oclock {
  /* transform: rotate(175deg) translateY(-323px) translateX(12px) rotate(184deg); */
  -webkit-transform: rotate(159deg) translateY(-304px) translateX(98px) rotate(-157deg);
          transform: rotate(159deg) translateY(-304px) translateX(98px) rotate(-157deg);
}

.at-7-oclock {
  /* transform: rotate(225deg) translateY(-171px) translateX(46px) rotate(-1deg); */
  -webkit-transform: rotate(-45deg) translateY(126px) translateX(-270px) rotate(45deg);
          transform: rotate(-45deg) translateY(126px) translateX(-270px) rotate(45deg);
}

.at-8-oclock {
  /* transform:rotate(270deg) translateY(-205px) translateX(28px) rotate(-3deg); */
  -webkit-transform: rotate(-36deg) translateY(45px) translateX(-211px) rotate(37deg);
          transform: rotate(-36deg) translateY(45px) translateX(-211px) rotate(37deg);
}

.subscripPosition {
  color: black;
  text-decoration: none;
  -webkit-transform: rotate(0deg) translateY(-253px) translateX(-60px) rotate(0deg);
          transform: rotate(0deg) translateY(-253px) translateX(-60px) rotate(0deg);
}

.transacpPosition {
  color: black;
  -webkit-transform: rotate(0deg) translateY(-170px) translateX(181px) rotate(0deg);
          transform: rotate(0deg) translateY(-170px) translateX(181px) rotate(0deg);
}

.businessLeads {
  color: black;
  -webkit-transform: rotate(0deg) translateY(-87px) translateX(199px) rotate(0deg);
          transform: rotate(0deg) translateY(-87px) translateX(199px) rotate(0deg);
}

.marketPosition {
  color: black;
  -webkit-transform: rotate(0deg) translateY(4px) translateX(184px) rotate(0deg);
          transform: rotate(0deg) translateY(4px) translateX(184px) rotate(0deg);
}

.reportsPosition {
  color: black;
  -webkit-transform: rotate(0deg) translateY(85px) translateX(-60px) rotate(0deg);
          transform: rotate(0deg) translateY(85px) translateX(-60px) rotate(0deg);
}

.accountsPosition {
  color: black;
  -webkit-transform: rotate(0deg) translateY(-50px) translateX(-291px) rotate(0deg);
          transform: rotate(0deg) translateY(-50px) translateX(-291px) rotate(0deg);
}

.wishListPosition {
  color: black;
  -webkit-transform: rotate(0deg) translateY(-197px) translateX(-346px) rotate(0deg);
          transform: rotate(0deg) translateY(-197px) translateX(-346px) rotate(0deg);
}

.digitalMediaPosition {
  color: black;
  -webkit-transform: rotate(0deg) translateY(-336px) translateX(-291px) rotate(0deg);
          transform: rotate(0deg) translateY(-336px) translateX(-291px) rotate(0deg);
}

.rocket {
  position: absolute;
  right: 10px;
  bottom: 50px;
  -webkit-animation: animate 15s linear infinite;
          animation: animate 15s linear infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    -webkit-transform: translate(-10%, 0);
            transform: translate(-10%, 0);
  }
  100% {
    -webkit-transform: translate(10%, -80vh);
            transform: translate(10%, -80vh);
  }
}

@keyframes animate {
  0%,
  100% {
    -webkit-transform: translate(-10%, 0);
            transform: translate(-10%, 0);
  }
  100% {
    -webkit-transform: translate(10%, -80vh);
            transform: translate(10%, -80vh);
  }
}

.cloudBg {
  position: absolute;
  bottom: 0px;
  /* z-index: -1; */
  -webkit-animation: cloud 10s linear infinite;
          animation: cloud 10s linear infinite;
}

.cloudBg img {
  background-position: center;
}
@-webkit-keyframes cloud {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  /* 25%,75% {
    opacity: 1;
  } */
  100% {
    opacity: 1;
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
  }
}
@keyframes cloud {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  /* 25%,75% {
    opacity: 1;
  } */
  100% {
    opacity: 1;
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
  }
}

.cards {
  display: flex;
  justify-content: space-between;
}
.cards__item {
  height: 250px;
  width: 300px;
}
.itdmcard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin: 5px;
}

.webDevCard {
  background-image: linear-gradient(45deg, #3503ad, #f7308c);
}
.SocialMediaCard {
  background-image: linear-gradient(45deg, #cf0, #09afff);
}
.GoogleMarketingcard {
  background-image: linear-gradient(45deg, #e91e63, #ffeb3b);
}
.mobileAppCard {
  background: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}
.smsCard {
  background: linear-gradient(
    90deg,
    rgba(205, 124, 214, 1) 0%,
    rgba(152, 202, 225, 1) 100%
  );
}
.offlineMarketingCard {
  background: linear-gradient(
    90deg,
    rgba(231, 102, 37, 1) 0%,
    rgba(56, 69, 99, 1) 100%
  );
}
.card__frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card__title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: white;
}
.card__overlay {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: pointer;
}
.card__overlay::before {
  content: 'Read';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: white;
}
.card__overlay:hover,
.card__overlay:focus {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 0;
  opacity: 0.9;
}
.card__overlay:hover::before,
.card__overlay:focus::before {
  content: none;
}
.webDevCard .card__overlay {
  background-image: linear-gradient(45deg, #3503ad, #f7308c);
}
.SocialMediaCard .card__overlay {
  background-image: linear-gradient(45deg, #cf0, #09afff);
}
.GoogleMarketingcard .card__overlay {
  background-image: linear-gradient(45deg, #e91e63, #ffeb3b);
}
.mobileAppCard .card__overlay {
  background: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}
.smsCard .card__overlay {
  background: linear-gradient(
    90deg,
    rgba(205, 124, 214, 1) 0%,
    rgba(152, 202, 225, 1) 100%
  );
}
.offlineMarketingCard .card__overlay {
  background: linear-gradient(
    90deg,
    rgba(231, 102, 37, 1) 0%,
    rgba(56, 69, 99, 1) 100%
  );
}
.card__content {
  z-index: 1;
  padding: 20px;
  line-height: 1.4;
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  pointer-events: none;
  transition: 0s;
}
.card__overlay:hover ~ .card__content {
  opacity: 1;
  visibility: visible;
  transition: 0.2s 0.3s;
}
.card__content h2 {
  margin: 0;
  margin-bottom: 16px;
}

.brandWrap {
  display: flex;
  justify-content: space-around;
  margin: 5px;
  background-color: green;
  text-align: center;
}

.education {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.credentialing {
  --bg-color: #b8f9d3;
  --bg-color-light: #e2fced;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
  --bg-color: #ceb2fc;
  --bg-color-light: #f0e7ff;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
  --bg-color: #dce9ff;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.mhcCard {
  width: 220px;
  height: 220px;
  margin: 8px;
  background: #fff;
  border-radius: 15px 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.mhcCard:hover {
  background-color: var(--bg-color);
  -webkit-transform: translateY(-5px) scale(1.005) translateZ(0);
          transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.mhcCard:hover .overlay {
  -webkit-transform: scale(4) translateZ(0);
          transform: scale(4) translateZ(0);
}

.mhcCard:hover .markcircle {
  border-color: var(--bg-color-light);
}

.mhcCard:hover p {
  color: var(--text-color-hover);
}

.mhcCard:active {
  -webkit-transform: scale(1) translateZ(0);
          transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.mhcCard p {
  font-size: 17px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.markcircle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.markcircle:after {
  content: '';
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  border-radius: 50%;
  transition: opacity 0.3s ease-out;
}


.team-page-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}

.team-page-section {
  position: relative;
  padding-top: 20px;
}

.team-page-section .team-block {
  margin-bottom: 60px;
}

.coreTeam-title {
  position: relative;
  z-index: 1;
  margin-bottom: 45px;
}

.coreTeam-title .title {
  position: relative;
  color: #bd0000;
  font-weight: bolder;
  font-family: 'Tangerine', cursive;
  font-size: 40px;
}

.team-section {
  position: relative;
  padding: 80px 0px 60px;
}

.team-section.style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-position: center center;
  background-repeat: no-repeat;
}

.team-section .title-box {
  position: relative;
  text-align: center;
}

.team-section .title-box h2 {
  position: relative;
  color: #222222;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 50px;
}

.team-section .title-box h2 span {
  color: #ff9600;
}

.team-block {
  position: relative;
}

.team-block .inner-box {
  position: relative;
  /* padding:40px 70px 28px 0px; */
  padding: 14px 70px 14px 0px;
}

.team-block .inner-box:before {
  position: absolute;
  content: '';
  right: 0px;
  top: 0px;
  left: 70px;
  bottom: 0px;
  border-radius: 10px;
  background-color: #f5f5f5;
  transition: all 600ms ease;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.team-block .inner-box::after {
  position: absolute;
  content: '';
  top: 0px;
  left: 70px;
  bottom: 0px;
  width: 0%;
  border-radius: 10px;
  transition: all 600ms ease;
  background-image: linear-gradient(to bottom, #ffca08 0%, #f70067 100%);
}

.team-block .inner-box:hover::after {
  width: 81%;
}

.team-block .inner-box .social-icons {
  position: absolute;
  right: 25px;
  top: 100px;
  z-index: 1;
}

.team-block .inner-box .social-icons li {
  position: relative;
  margin-bottom: 20px;
}

.team-block .inner-box .social-icons li a {
  position: relative;
  color: #e40000;
  font-size: 18px;
  transition: all 0.4s;
}

.team-block .inner-box:hover .lower-content h3 a,
.team-block .inner-box:hover .social-icons li a,
.team-block .inner-box:hover .lower-content .designation {
  color: #ffffff;
}

.team-block .inner-box .image {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  /* background-color:#ffffff; */
  /* box-shadow:inset 0px 0px 35px rgba(0,0,0,0.20); */
}

.team-block .inner-box .image img {
  position: relative;
  width: 150px;
  display: block;
}

.team-block .inner-box .lower-content {
  position: relative;
  text-align: left;
  z-index: 1;
  padding: 30px 15px 0px 110px;
}

.team-block .inner-box .lower-content h3 {
  position: relative;
  color: #bd0000;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3em;
}

.team-block .inner-box .lower-content h3 a {
  position: relative;
  color: #bd0000;
  transition: all 0.4s;
}

.team-block .inner-box .lower-content .designation {
  position: relative;
  margin-top: 5px;
  color: #777777;
  font-size: 14px;
}

.team-block.style-two .inner-box .lower-content h3 a,
.team-block.style-two .inner-box .social-icons li a {
  color: #ff9600;
}

.team-block.style-two .inner-box:hover .lower-content h3 a,
.team-block.style-two .inner-box:hover .social-icons li a {
  color: #ffffff;
}

.team-block.style-two .inner-box::after {
  background-image: linear-gradient(to bottom, #ffeb3c 0%, #ff9600 100%);
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.my-market {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
}
.my-market > * {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
}
.my-market-col {
  /* z-index:2000; */
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.search-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: white;
  height: 150px;
  padding: 15px !important;
  box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.1);
}
.my-market .search-box {
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  width: 60%;
  height: auto;
}
.my-market .search-bar {
  display: flex !important;
  height: 60px;
  border: 2px solid black;
  border-radius: 50px;
  width: 100%;
  overflow: hidden;
  padding: 0px !important;
}
.search-bar .category-dropdown {
  background: #feb913;
  width: 40%;
}
.category-dropdown .category-list {
  height: 100%;
  width: 100%;
  background: transparent;
  border: none !important;
  outline: none !important;
}
.category-dropdown .category-list:first-child {
  color: white !important;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.category-list option {
  background: #feb913;
}
.search-bar #search-bar {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px !important;
}
#search-bar::-webkit-input-placeholder {
  color: black !important;
}
#search-bar:-ms-input-placeholder {
  color: black !important;
}
#search-bar::placeholder {
  color: black !important;
}
.search-box .search-results {
  /* display:none; */
  position: absolute;
  top: 60px;
  left: 28.5%;
  width: 70%;
}
.search-box .search-results ul {
  background: white;
  width: 100%;
}
.search-box .search-results ul li {
  list-style-type: none;
  border-bottom: 1px solid #e6e6e6;
  padding: 6px;
}
.header-icons {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.header-icons div {
  padding: 15px !important;
}
.market-products {
  z-index: 1000;
  padding: 30px 0px !important;
  background: white;
  /* height:600px; */
  height: auto;
  box-shadow: 6px 0px 18px 1px rgba(0, 0, 0, 0.1);
}
.slider-box {
  position: relative;
}
.slider-handler {
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 3000;
}
.owl-prev,
.owl-next {
  visibility: hidden !important;
}
.left-handler {
  left: 10px;
  top: calc(50% - 22%);
}
.right-handler {
  right: 10px;
  top: calc(50% - 22%);
}
.slider-handler img {
  width: 30px;
  height: 30px;
}
.slider-box .item {
  border-right: 2px solid #f3f3f3;
}
.slider-box .item .img-bg {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
  background: #fff;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 6px solid #d7dbdf;
}
.slider-box .item .img-bg .inner-bg {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fbb326;
}
.slider-box .item .img-bg .inner-bg img {
  width: 95px !important;
  height: 72px !important;
}
.banner-row img {
  width: 90%;
}
.tagged-products-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.prod {
  width: 130px;
  height: 130px;
}
.prod img {
  width: 100%;
  height: 100%;
}
.tagged-products-wrapper .prod {
  border: 4px solid #dcdcdc;
  border-radius: 30px;
  padding: 20px;
}
.tagged-products-wrapper .prod:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .trending-products .prod{
    border:4px solid #dcdcdc;
    border-radius:30px;
}
.trending-products .prod:last-child{
    display: flex;
    justify-content: center;
    align-items: center;
} */
.market-members {
  /* display: flex!important;
    justify-content: center!important; */
  /* align-items: center; */
  /* flex-direction: column; */
  padding: 15px;
}
.product-form {
  padding: 15px;
  background: #fec80b;
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.product-form input {
  border: none !important;
  outline: none !important;
  padding: 0px 5px !important;
}
.product-form .form-control {
  padding: 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  height: inherit !important;
  border: none !important;
  outline: none !important;
}
.product-form select {
  border: none !important;
  outline: none !important;
}
#prod-inp {
  width: 80%;
}
.chktext {
  font-size: 12px;
}
.quan-col {
  padding-right: 0px !important;
}
.units-col {
  padding-left: 0px !important;
}
.sendreqbtn {
  background: red;
  border-radius: 20px !important;
  border: none !important;
  outline: none !important;
  color: white;
  padding: 4px 15px !important;
}
.members {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.member {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 20px !important;
  position: relative;
  left: 20px;
  width: 80%;
  height: 100px;
  background-image: linear-gradient(
    to right,
    #d8f3f8,
    #e4f5fd,
    #f0f8ff,
    #f9fbff,
    #ffffff
  );
  border-right: 2px solid #cac8c8;
  border-bottom: 4px solid #cac8c8;
  border-radius: 10px;
}
.member-icon {
  background: white;
  position: absolute;
  width: 100px;
  height: 100px;
  left: -50px;
  top: calc(50% - 50px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.icon1 {
  border: 10px solid #97c25f;
}
.icon2 {
  border: 10px solid #ffa13b;
}
.icon3 {
  border: 10px solid #ff5b4d;
}
.icon4 {
  border: 10px solid #8069ff;
}
.icon5 {
  border: 10px solid #40b9cc;
}
.style-elem {
  position: absolute;
  right: -15px;
  width: 15px;
  height: 80%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.style-elem-1 {
  background: #97c25f;
}
.style-elem-2 {
  background: #ffa13b;
}
.style-elem-3 {
  background: #ff5b4d;
}
.style-elem-4 {
  background: #8069ff;
}
.style-elem-5 {
  background: #40b9cc;
}
.buy-now {
  background-image: linear-gradient(
    to bottom,
    #eb3349,
    #ee3f47,
    #f04946,
    #f25344,
    #f45c43
  );
  color: white;
  padding: 4px 15px !important;
  border-radius: 20px;
  border: none;
  outline: none;
  margin-right: 10px;
}
.sell-here {
  background-image: linear-gradient(
    to bottom,
    #56ab2f,
    #6bb83c,
    #80c549,
    #94d356,
    #a8e063
  );
  color: white;
  padding: 4px 15px !important;
  border-radius: 20px;
  border: none;
  outline: none;
}

.slider-handler img {
  width: 20px;
  height: 20px;
}
.left-handler {
  left: 5px;
  top: calc(50% - 22%);
}
.right-handler {
  right: 5px;
  top: calc(50% - 22%);
}
.product-form {
  margin-top: 20px;
}
.product-form {
  background: #8dc73f;
}
.sendreqbtn {
  font-size: 18px;
  padding: 8px 22px !important;
}
.product-wrapper {
  padding: 15px !important;
}
.product {
  width: 100%;
  height: 100%;
  background: #f3f3f4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px !important;
}
.q-inp {
  width: 30%;
  border: none;
  outline: none;
  border: 1px solid #d2d2d2;
  padding: 0px 5px !important;
}
.company-name {
  font-size: 13px;
}
.contact-supplier {
  border: none;
  outline: none;
  background-image: linear-gradient(
    to bottom,
    #00aaeb,
    #21b6ed,
    #3cc2ed,
    #54cdee,
    #6cd8ee
  );
  color: white;
  padding: 5px 10px !important;
  border-radius: 10px;
}
.see-more {
  text-decoration: underline;
  cursor: pointer;
}
.mm-side-menu {
  padding: 0px !important;
}
.mm-side-menu .card {
  margin-bottom: 6px;
  cursor: pointer;
}
.mm-side-menu .card-header {
  position: relative;
  background: #f8941d;
  border: none !important;
  outline: none !important;
  padding: 10px;
  overflow: hidden;
}
.mm-side-menu .card-header h4 {
  color: black;
  font-size: 18px;
  position: relative;
  left: 28px;
}
.mm-side-menu .card-header button {
  background: transparent !important;
  color: white;
  padding: 0px !important;
  border: none;
  outline: none;
  position: absolute;
  right: 30px;
}
.mm-side-menu .card-header button i {
  font-size: 25px;
  color: black;
}
.related-categories li {
  list-style-type: none;
  padding: 10px !important;
  font-size: 18px;
  transition: all 0.5s;
}
.related-categories li:hover {
  background: #e6e6e6;
  font-weight: bold;
}
.MuiSlider-root {
  color: #0089d0 !important;
}

.slider-handler img {
  width: 30px;
  height: 30px;
}
.left-handler {
  left: 10px;
  top: calc(50% - 22%);
}
.right-handler {
  right: 10px;
  top: calc(50% - 22%);
}
.single-product-box {
  z-index: 1000;
  padding: 30px 0px !important;
  background: white;
  /* height:600px; */
  height: auto;
  box-shadow: 6px 0px 18px 1px rgba(0, 0, 0, 0.1);
}
#prod-quan-inp {
  width: 20%;
  padding: 0px 5px;
}
.rating-and-enquiry {
  padding: 0px 0px 0px 30px;
}
.rating-and-enquiry {
  width: 50% !important;
}
.enquiry-submit {
  border: none;
  outline: none;
  background: #f15a22;
  color: white;
  border-radius: 5px;
  padding: 10px;
}
.add-to-list {
  border: none;
  outline: none;
  background: #feb913;
  color: white;
  border-radius: 5px;
  padding: 10px;
}
.comments-box {
  padding: 20px;
  border: 2px solid #e6e6e6;
  border-radius: 15px;
}
.comments-box li {
  padding: 10px;
  border-bottom: 2px solid #e6e6e6;
  list-style-type: none;
}

.product-wrapper {
  padding: 15px !important;
}
.product {
  width: 100%;
  height: 100%;
  background: #f3f3f4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px !important;
}
.q-inp {
  width: 30%;
  border: none;
  outline: none;
  border: 1px solid #d2d2d2;
  padding: 0px 5px !important;
}
.company-name {
  font-size: 13px;
}
.contact-supplier {
  background-image: linear-gradient(
    to bottom,
    #00aaeb,
    #21b6ed,
    #3cc2ed,
    #54cdee,
    #6cd8ee
  );
  color: white;
  padding: 5px 10px !important;
  border-radius: 10px;
}

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
} */

/* .swiper-container {
  width: 40%;
  height: 300px;
  margin-left: 0px;
  margin-right: 0px;
} */

/* .swiper-slide {
  background-size: cover;
  background-position: center;
} */

.mySwiper2 {
  height: auto;
  width: 100%;
}

.mySwiper {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiper2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

* {
  margin: 0px;
  padding: 0px;
}
.sections a {
  text-decoration: none !important;
  color: black !important;
}
.form-div {
  padding-top: 10px !important;
}
/* .form{
    width:400px;
    height:350px;
    background-image: linear-gradient(to bottom, #70d0f6, #93d9f6, #b1e2f7, #ccebf8, #e7f4f9);
    border-radius:20px;
    padding:20px!important;
    margin:0 auto!important;
} */
.form input {
  border: none !important;
  outline: none !important;
  border-radius: 20px !important;
}
.form label {
  font-weight: bold !important;
}
.form button {
  border: none;
  outline: none;
  width: 40%;
  padding: 10px !important;
  color: white;
  background: #ee1c24;
  border-radius: 10px;
  font-weight: bold;
}
.connect-to-feed button {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px !important;
  color: black;
  background: #00baf2;
  border-radius: 10px;
  font-weight: bold;
}
.sections .card {
  width: 250px;
  height: 200px;
  border-radius: 20px;
  margin: 10px 0px 20px 40px;
}
.db-sec {
  width: 300px;
  height: 250px;
  border-radius: 20px;
  margin: 10px auto 30px auto !important;
}
.sections .card-header,
.db-sec .card-header {
  color: black;
  font-weight: bold;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.sections .card-body,
.db-sec .card-body {
  padding: 5px 0px 0px !important;
}
.sections .card-body ul,
.db-sec .card-body ul {
  padding: 0px 0px 0px 10px !important;
}
.sections .card-body li,
.db-sec .card-body li {
  list-style-type: none;
  font-weight: bold;
  margin-bottom: 10px;
}
.sections .card-body .icon img {
  width: 80px;
  height: 90px;
}
.sec-1 .card-header,
.sec-2 .card-header,
.sec-3 .card-header {
  background: #ffcb05;
}
.sec-4 .card-header,
.sec-5 .card-header,
.sec-6 .card-header {
  background: #c7ebfc;
}
.sec-7 .card-header,
.sec-8 .card-header,
.sec-9 .card-header {
  background: #bfd730;
}
.sec-7 .card-body li {
  font-size: 14px;
}
.sec-9 .card-body li {
  font-size: 14px;
}
.sec-1 img,
.sec-2 img,
.sec-4 img {
  position: absolute;
  right: 20px;
}
.ant img {
  height: 280px;
}
@media only screen and (min-width: 801px) and (max-width: 1550px) {
  .form {
    width: 300px;
    height: 325px;
  }
  .form label {
    font-size: 13px;
  }
  .form input {
    height: 30px;
  }
  .form button {
    padding: 3px !important;
  }
  .form-div .sections {
    padding: 0px !important;
  }
  .sections .card {
    width: 200px;
    height: 180px;
    border-radius: 20px;
    margin: 10px 0px 20px 10px;
  }
  .sections .card-header {
    padding: 10px;
  }
  .sections .card-header h5 {
    font-size: 14px;
  }
  .sections .card-body .icon img {
    width: 60px;
    height: 70px;
  }
  .sections .card-body li {
    font-size: 11px;
  }
}

.ppform-row {
  background-image: linear-gradient(
    to right top,
    #5433ff,
    #005eff,
    #007bff,
    #0092ff,
    #00a6ff,
    #00b4ff,
    #00c1ff,
    #00ceff,
    #00dafe,
    #1ee6fb,
    #40f1f7,
    #5ffbf1
  );
  padding: 20px !important;
}
#ppform label {
  font-weight: bold !important;
}
#ppform input {
  width: 50%;
}
#ppform input {
  padding: 10px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.ppform-submit-btn {
  background-image: linear-gradient(
    to bottom,
    #f0b817,
    #f1a303,
    #f18d00,
    #ef7605,
    #eb5d12
  );
  color: white;
  padding: 5px;
  width: 50%;
  border: 2px solid black;
}

.pp-side-menu {
  padding: 0px !important;
}
.pp-side-menu h2 {
  color: #00a651;
  font-size: 30px;
  margin-top: 10px;
}
.pp-side-menu .card {
  margin-bottom: 2px;
  /* border-top-right-radius: 50px!important; */
  cursor: pointer;
}
.pp-side-menu .card-header {
  position: relative;
  background: #c7eafb;
  /* border-top-right-radius: 50px!important; */
  border: none !important;
  outline: none !important;
  /* border-bottom:2px solid white!important; */
  padding: 10px;
  overflow: hidden;
}
.pp-side-menu .card-header h4 {
  color: black;
  font-size: 18px;
  position: relative;
  left: 28px;
}
.pp-side-menu .card-header button {
  background: transparent;
  color: white;
  padding: 0px !important;
  border: none;
  outline: none;
  position: absolute;
  right: 30px;
}
.pp-side-menu .card-header button i {
  font-size: 25px;
}
.pp-side-menu .card-header .shape {
  width: 50px;
  height: 50px;
  background: #ee145b;
  border: 1px solid white;
  position: absolute;
  top: -3px;
  left: -32px;
  -webkit-transform: rotate(46deg);
          transform: rotate(46deg);
}
@media only screen and (min-width: 801px) and (max-width: 1550px) {
  .pp-side-menu h2 {
    color: #00a651;
    font-size: 20px;
  }
  .pp-side-menu .card-header {
    padding: 8px;
  }
  .pp-side-menu .card-header h4 {
    color: white;
    font-size: 13px;
    position: relative;
    left: 28px;
  }
  .pp-side-menu .card-header button {
    bottom: 10px;
  }
  .pp-side-menu .card-header button i {
    font-size: 16px;
  }
  .pp-side-menu .card-header .shape {
    top: -9px;
    left: -40px;
  }
}

.production-table table {
  width: 100%;
}
.production-table table th {
  text-align: center;
  vertical-align: middle;
  border: 1px solid black;
}
.production-table table .main-row th {
  background: #feb913;
}
.area-ha {
  background: #e0ec8e;
}
.production-mt {
  background: #c0e4cd;
}

.biproducts-table table {
  width: 100%;
}
.biproducts-table table th {
  text-align: center;
  vertical-align: middle;
  border: 1px solid black;
}
.biprods-row th {
  background: #8dc63f;
}
.biprods-text-wrapper {
  width: 80%;
  background: white;
  padding: 20px !important;
  border-radius: 20px;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.1);
}
.biprods-text-wrapper .more-info {
  color: #2e3192;
}
.biprods-text-wrapper {
  color: black;
  font-weight: bold;
}

.scheme-wrapper {
  width: 80%;
  height: 300px;
  position: relative;
  background: #e9eef1;
  border-radius: 10px;
  padding: 15px !important;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
.sp-decorator {
  width: 60%;
  height: 30px;
  border-radius: 10px;
  position: absolute;
  top: -15px;
  left: calc(50% - 30%);
}
.sp-fpo-decorator {
  background: #f7c346;
}
.sp-msme-decorator {
  background: #f17f57;
}
.sp-farmer-decorator {
  background: #6ab25e;
}
.sp-exports-decorator {
  background: #4ab6ca;
}

.processing-content .card {
  border: 1px solid black;
  border-radius: 20px;
}
.processing-content .card .card-header {
  background: #f7941d;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.processing-content .card .card-header h5 {
  color: white;
}
.pu-icon {
  padding: 20px !important;
}
.pu-icon img {
  width: 180px;
  height: 120px;
}

.trade-content .graph-bg {
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url('https://www.feedorg.com/assets/product-profile/candlesticks.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.trade-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.trade-content .text-content-wrapper {
  width: 60%;
  height: auto;
  padding: 25px !important;
  background: white;
  border-radius: 50px;
  position: absolute;
  left: calc(50% - 30%);
  bottom: -60px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
.trade-content .text-content-wrapper p {
  font-weight: 500;
}
.trade-content .text-content-wrapper .trade-top-left-border {
  position: absolute;
  left: 0;
  top: 0;
  height: 290px;
}
.trade-content .text-content-wrapper .trade-bottom-right-border {
  position: absolute;
  right: 0;
  bottom: 0;
}
.header-titles-wrapper {
  width: 70%;
  margin-top: 8rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.header-titles-wrapper .header-title-one,
.header-title-two {
  position: relative;
  width: 30%;
  height: 60px;
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    #0161ea,
    #0078f3,
    #008df8,
    #00a0f9,
    #00b2f8
  );
}
.header-title-one h5,
.header-title-two h5 {
  color: white;
}
.header-titles-wrapper .globe {
  position: absolute;
}
.header-titles-wrapper img.left-globe {
  right: -20px;
}
.header-titles-wrapper img.right-globe {
  left: -20px;
}
.note-points-wrapper {
  width: 85%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.note-points-wrapper ul li {
  padding: 12px !important;
  margin-bottom: 10px;
  list-style-type: none;
  border-radius: 50px;
  background: white;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
.note-points-wrapper ul li span {
  font-weight: 600;
  color: #2e3192;
}
.note-points-wrapper ul li span::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ed1c24;
  margin-right: 10px !important;
}

.utp-main-title {
  color: #ed1c24;
  font-weight: bold;
}
.utp-flag-row .card-body {
  padding: 10px !important;
}
.utp-flag-row .card {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 2px solid black;
}
.utp-flag-row .card-header {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: #fdb913;
}
.country-flag {
  padding: 10px;
}
.country-flag h6 {
  font-size: 13px;
}
.utp-policies {
  width: 80%;
  margin: 0 auto !important;
}
.utp-policies ul li {
  /* display:inline-flex;
    justify-content: space-between; */
  text-align: center;
  list-style-type: none;
  width: 15%;
  padding: 10px !important;
  color: black;
  border: 1px solid black;
  border-radius: 50px;
  margin-right: 10px !important;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
.utp-text-content {
  position: relative;
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 25px !important;
}
.utp-text-content p {
  font-weight: 500;
}
.utp-top-left-border {
  position: absolute;
  left: 0;
  top: 0;
}
.utp-bottom-right-border {
  position: absolute;
  right: 0;
  bottom: 0;
}
.utp-text-content .more-info {
  color: #2e3192;
}

