.seasonOneWrap {
  text-align: justify;
  text-justify: inter-word;
}

.seasonTwoWrap {
  text-align: justify;
  text-justify: inter-word;
}

.seasonthreeWrap {
  text-align: justify;
  text-justify: inter-word;
}
