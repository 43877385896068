.my-farm-home .grass-logo {
  height: 13em;
}

.my-farm-home .ant-logo {
  height: 20em;
}

.feedConnect {
  background-color: #a4cf3e !important;
}

.my-farm-home {
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .side-navbar {
    position: absolute !important;
  }

  .my-farm-home .image-grid {
    display: none;
  }

  .my-farm-wrap {
    position: relative !important;
    left: 0px !important;
    margin-left: 57px;
  }

  .my-farm-home .nav-link img {
    width: 7em;
    height: 7em;
  }

  .my-farm-home {
    margin-top: 0;
  }

  .feedConnect {
    margin-top: 2em !important;
  }
}
