/* Footer Section */
.footer-section {
  padding: 20px;
  background-color: #f37835;
  color: white;
  /* height: 300px; */
}

.footer-section-newsletter {
  margin: auto;
  /* top: -100px; */
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 15px 15px;
  /* height: 200px; */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.section-separator::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 3px;
  width: 50px;
  border-radius: 3px;
  z-index: 2;
  /* background-color: #007aff; */
  border-bottom: 1px solid white;
  margin-left: -25px;
}
.footerTitleBtmBrd {
  border-width: 0 0 2px;
  border-style: solid;
}

.footer-section-links ul {
  padding: 0rem !important;
}

.footer-section-links ul li {
  list-style-type: none;
}

.footer-section-links ul li a {
  padding: 0rem !important;
  text-decoration: none;
  color: white !important;
  font-size: 14px;
}

.conditions-row {
  border-top: 1px solid white;
}

/* Media Queries */
@media only screen and (max-width: 426px) {
  .links-row {
    display: none;
  }
  .conditions-row {
    border: none;
  }
}

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.banner-right {
  animation-name: float-bob;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* body {
  background: #fbfbfd;
} */

.new_footer_area {
  background: #fbfbfd;
  margin-top: 25px;
}

.new_footer_top {
  padding: 100px 0px 270px;
  position: relative;
  overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}
.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}
.btn_get:hover {
  color: #fff;
  background: #6754e2;
  border-color: #6754e2;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  /* color: #5e2ced; */
  color: rgb(230, 81, 13);
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: '\e741';
}
.ti-twitter-alt:before {
  content: '\e74b';
}
.ti-vimeo-alt:before {
  content: '\e74a';
}
.ti-pinterest:before {
  content: '\e731';
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
.t_color {
  color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url('http://droitthemes.com/html/saasland/img/seo/footer_bg.png')
    no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  /* background: url("https://1.bp.blogspot.com/-mvKUJFGEc-k/XclCOUSvCnI/AAAAAAAAUAE/jnBSf6Fe5_8tjjlKrunLBXwceSNvPcp3wCLcBGAsYHQ/s1600/volks.gif") no-repeat center center; */
  /* background-image: url("../assets/my-market/bullock-cart.png"); */
  /* width: 330px; */
  height: 105px;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 60s linear infinite;
  animation: myfirst 60s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url('https://1.bp.blogspot.com/-hjgfxUW1o1g/Xck--XOdlxI/AAAAAAAAT_4/JWYFJl83usgRFMvRfoKkSDGd--_Sv04UQCLcBGAsYHQ/s1600/cyclist.gif')
    no-repeat center center;
  width: 88px;
  height: 100px;
  background-size: 100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 45s linear infinite;
  animation: myfirst 45s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_three {
  height: 105px;
  background-size: 100%;
  position: absolute;
  /* bottom: 0; */
  left: 30%;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

/*************footer End*****************/
