.slider-handler img {
  width: 30px;
  height: 30px;
}
.left-handler {
  left: 10px;
  top: calc(50% - 22%);
}
.right-handler {
  right: 10px;
  top: calc(50% - 22%);
}
.single-product-box {
  z-index: 1000;
  padding: 30px 0px !important;
  background: white;
  /* height:600px; */
  height: auto;
  box-shadow: 6px 0px 18px 1px rgba(0, 0, 0, 0.1);
}
#prod-quan-inp {
  width: 20%;
  padding: 0px 5px;
}
.rating-and-enquiry {
  padding: 0px 0px 0px 30px;
}
.rating-and-enquiry {
  width: 50% !important;
}
.enquiry-submit {
  border: none;
  outline: none;
  background: #f15a22;
  color: white;
  border-radius: 5px;
  padding: 10px;
}
.add-to-list {
  border: none;
  outline: none;
  background: #feb913;
  color: white;
  border-radius: 5px;
  padding: 10px;
}
.comments-box {
  padding: 20px;
  border: 2px solid #e6e6e6;
  border-radius: 15px;
}
.comments-box li {
  padding: 10px;
  border-bottom: 2px solid #e6e6e6;
  list-style-type: none;
}

.product-wrapper {
  padding: 15px !important;
}
.product {
  width: 100%;
  height: 100%;
  background: #f3f3f4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px !important;
}
.q-inp {
  width: 30%;
  border: none;
  outline: none;
  border: 1px solid #d2d2d2;
  padding: 0px 5px !important;
}
.company-name {
  font-size: 13px;
}
.contact-supplier {
  background-image: linear-gradient(
    to bottom,
    #00aaeb,
    #21b6ed,
    #3cc2ed,
    #54cdee,
    #6cd8ee
  );
  color: white;
  padding: 5px 10px !important;
  border-radius: 10px;
}
