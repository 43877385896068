li:hover > ul.dropdown-menu {
  display: block;
  width: 100%;
  border-bottom: 2px solid orange;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

.categoryBtn {
  width: 100%;
  height: 100%;
  font-size: 600;
  padding: 20px;
}
