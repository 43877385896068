@keyframes rotate {
  0%,
  50% {
    /* Keep the element at 12 o'clock until halfway through animation. */
    /* transform: rotate(0) translateY(-197px) translateX(-29px) rotate(0deg); */
    transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
  }
}

#centre {
  /* Position circles in the centre of the viewport. */
  /* position: fixed;
  left: 50%;
  top: 50%; */

  /* IE 10 bug: force hardware acceleration to prevent edge pixels
     being left behind on animation. */
  /* transform: rotateZ(0.01deg); */

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.circle {
  /* background: red; */
  border-radius: 50%;
  position: absolute;
}

/* .circle img {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
} */

.middle {
  height: 200px;
  width: 200px;
  left: -100px;
  top: -100px;
  background-image: 'URL("${process.env.PUBLIC_URL}/assets/my-dashboard/quickmenu.png")';
}

.outer {
  /* Note: first half of animation duration is spent at 12 o'clock. */
  animation: rotate 2s ease-out 1;
  /* height: 60px;
  width: 60px; */
  left: -30px; /* top/left equal to radius. */
  top: -30px;
}

.trancircle {
  width: 100px;
  height: 100px;
  border: 6px solid coral;
  border-radius: 50% 50%;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
}

.at-10-oclock {
  /* transform: rotate(-36deg) translateY(-210px) translateX(-37px) rotate(-8deg); */
  transform: rotate(-36deg) translateY(-19px) translateX(-113px) rotate(35deg);
}

.at-12-oclock {
  transform: rotate(0) translateY(5px) translateX(16px) rotate(0deg);
  z-index: 2;
}

.at-2-oclock {
  /* transform: rotate(45deg) translateY(-7px) translateX(10px) rotate(-47deg); */
  transform: rotate(45deg) translateY(-56px) translateX(125px) rotate(-47deg);
  z-index: 1; /* Make this slide out from between 12 and 4 o'clock. */
}

.at-4-oclock {
  /* transform: rotate(90deg) translateY(-144px) translateX(-28px) rotate(0deg); */
  transform: rotate(90deg) translateY(-175px) translateX(158px) rotate(-90deg);
}

.at-5-oclock {
  transform: rotate(135deg) translateY(-284px) translateX(97px) rotate(-133deg);
}

.at-6-oclock {
  /* transform: rotate(175deg) translateY(-323px) translateX(12px) rotate(184deg); */
  transform: rotate(159deg) translateY(-304px) translateX(98px) rotate(-157deg);
}

.at-7-oclock {
  /* transform: rotate(225deg) translateY(-171px) translateX(46px) rotate(-1deg); */
  transform: rotate(-45deg) translateY(126px) translateX(-270px) rotate(45deg);
}

.at-8-oclock {
  /* transform:rotate(270deg) translateY(-205px) translateX(28px) rotate(-3deg); */
  transform: rotate(-36deg) translateY(45px) translateX(-211px) rotate(37deg);
}

.subscripPosition {
  color: black;
  text-decoration: none;
  transform: rotate(0deg) translateY(-253px) translateX(-60px) rotate(0deg);
}

.transacpPosition {
  color: black;
  transform: rotate(0deg) translateY(-170px) translateX(181px) rotate(0deg);
}

.businessLeads {
  color: black;
  transform: rotate(0deg) translateY(-87px) translateX(199px) rotate(0deg);
}

.marketPosition {
  color: black;
  transform: rotate(0deg) translateY(4px) translateX(184px) rotate(0deg);
}

.reportsPosition {
  color: black;
  transform: rotate(0deg) translateY(85px) translateX(-60px) rotate(0deg);
}

.accountsPosition {
  color: black;
  transform: rotate(0deg) translateY(-50px) translateX(-291px) rotate(0deg);
}

.wishListPosition {
  color: black;
  transform: rotate(0deg) translateY(-197px) translateX(-346px) rotate(0deg);
}

.digitalMediaPosition {
  color: black;
  transform: rotate(0deg) translateY(-336px) translateX(-291px) rotate(0deg);
}

.rocket {
  position: absolute;
  right: 10px;
  bottom: 50px;
  animation: animate 15s linear infinite;
}

@keyframes animate {
  0%,
  100% {
    transform: translate(-10%, 0);
  }
  100% {
    transform: translate(10%, -80vh);
  }
}

.cloudBg {
  position: absolute;
  bottom: 0px;
  /* z-index: -1; */
  animation: cloud 10s linear infinite;
}

.cloudBg img {
  background-position: center;
}
@keyframes cloud {
  0% {
    transform: scaleX(1);
  }
  /* 25%,75% {
    opacity: 1;
  } */
  100% {
    opacity: 1;
    transform: scaleX(1.2);
  }
}
