.scheme-wrapper {
  width: 80%;
  height: 300px;
  position: relative;
  background: #e9eef1;
  border-radius: 10px;
  padding: 15px !important;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
.sp-decorator {
  width: 60%;
  height: 30px;
  border-radius: 10px;
  position: absolute;
  top: -15px;
  left: calc(50% - 30%);
}
.sp-fpo-decorator {
  background: #f7c346;
}
.sp-msme-decorator {
  background: #f17f57;
}
.sp-farmer-decorator {
  background: #6ab25e;
}
.sp-exports-decorator {
  background: #4ab6ca;
}
