.countryCard {
  width: 220px;
  height: 220px;
  margin: 8px;
  background: #fff;
  border-radius: 15px 15px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* position: relative; */
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.countryCard:hover {
  background-color: #ffd861;
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.countryCard:hover .overlay {
  transform: scale(4) translateZ(0);
}

.countryCard:hover p {
  color: var(--text-color-hover);
}

.countryCard:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.countryCard p {
  font-size: 17px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}
