.cards {
  display: flex;
  justify-content: space-between;
}
.cards__item {
  height: 250px;
  width: 300px;
}
.itdmcard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin: 5px;
}

.webDevCard {
  background-image: linear-gradient(45deg, #3503ad, #f7308c);
}
.SocialMediaCard {
  background-image: linear-gradient(45deg, #cf0, #09afff);
}
.GoogleMarketingcard {
  background-image: linear-gradient(45deg, #e91e63, #ffeb3b);
}
.mobileAppCard {
  background: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}
.smsCard {
  background: linear-gradient(
    90deg,
    rgba(205, 124, 214, 1) 0%,
    rgba(152, 202, 225, 1) 100%
  );
}
.offlineMarketingCard {
  background: linear-gradient(
    90deg,
    rgba(231, 102, 37, 1) 0%,
    rgba(56, 69, 99, 1) 100%
  );
}
.card__frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card__title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: white;
}
.card__overlay {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: pointer;
}
.card__overlay::before {
  content: 'Read';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: white;
}
.card__overlay:hover,
.card__overlay:focus {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 0;
  opacity: 0.9;
}
.card__overlay:hover::before,
.card__overlay:focus::before {
  content: none;
}
.webDevCard .card__overlay {
  background-image: linear-gradient(45deg, #3503ad, #f7308c);
}
.SocialMediaCard .card__overlay {
  background-image: linear-gradient(45deg, #cf0, #09afff);
}
.GoogleMarketingcard .card__overlay {
  background-image: linear-gradient(45deg, #e91e63, #ffeb3b);
}
.mobileAppCard .card__overlay {
  background: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}
.smsCard .card__overlay {
  background: linear-gradient(
    90deg,
    rgba(205, 124, 214, 1) 0%,
    rgba(152, 202, 225, 1) 100%
  );
}
.offlineMarketingCard .card__overlay {
  background: linear-gradient(
    90deg,
    rgba(231, 102, 37, 1) 0%,
    rgba(56, 69, 99, 1) 100%
  );
}
.card__content {
  z-index: 1;
  padding: 20px;
  line-height: 1.4;
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  pointer-events: none;
  transition: 0s;
}
.card__overlay:hover ~ .card__content {
  opacity: 1;
  visibility: visible;
  transition: 0.2s 0.3s;
}
.card__content h2 {
  margin: 0;
  margin-bottom: 16px;
}

.brandWrap {
  display: flex;
  justify-content: space-around;
  margin: 5px;
  background-color: green;
  text-align: center;
}
