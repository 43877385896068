.side-navbar {
  /* box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55); */
  /* border-radius: 5px 5px; */
  /* border-right: 1px solid #f6f4f5; */
  /* border-top-right-radius: 5px; */
}

.side-navbar ~ div {
  overflow-x: hidden;
}

@media only screen and (max-width: 769px) {
  .side-navbar ~ div {
    position: relative;
    left: 57px;
    min-height: 606px;
    width: calc(100% - 57px);
  }

  .side-navbar {
    position: absolute;
  }
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}
