.brd {
  border: 1px solid red;
}

.swiper-button-next:after {
  /* content: "f0a9" !important; */
  /* background-image: url(public/assets/common/next.png); */
  /* background-image: URL("${process.env.PUBLIC_URL}/assets/common/next.png"); */
  /* color: red; */
}
/* carousel section */
.carousel-section {
  /* position: relative; */
  /* top: 240px; */
}
.carousel-inner {
  height: 565px;
  /* border-radius: 0px 0px 20px 20px; */
}

.carousel-section .carousel-inner img {
  height: 100% !important;
  animation: thing 20s;
}

@keyframes thing {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

/* How FEED Works */
.section-heading {
  margin: 10px;
  padding: 2px;
  background-color: orange;
  color: white;
  border-radius: 10px 10px;
}

.oceanWrap {
  /* position:relative;
  text-align:center; */
  /* height: 400px; */
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
  color: white;
  /* border: 1px solid red; */
  height: 100px;
  /* border-radius: 25px 25px; */
}

.waves {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 150px;
  max-height: 150px;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -5s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}

.shipmove {
  animation: shipmove 25s linear infinite;
}

@keyframes shipmove {
  0%,
  100% {
    transform: translateX(20%) rotate(-1deg) rotateX(5deg);
  }
  100% {
    transform: translateX(900px);
  }
}

/* Media Queries */

@media only screen and (max-width: 426px) {
  /* .carousel-section {
    display: none !important;
  } */

  .resp-events-view {
    display: none;
  }
}
@media only screen and (min-width: 424px) and (max-width: 768px) {
  /* carousel section */
  /* .carousel-inner {
    height: 350px;
  }

  .carousel-section .carousel-inner img {
    height: 100% !important;
    animation: thing 20s;
  } */
}

/* .events-section {
  height: 590px;
  border:1px solid red;
} */
