@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
.team-page-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}

.team-page-section {
  position: relative;
  padding-top: 20px;
}

.team-page-section .team-block {
  margin-bottom: 60px;
}

.coreTeam-title {
  position: relative;
  z-index: 1;
  margin-bottom: 45px;
}

.coreTeam-title .title {
  position: relative;
  color: #bd0000;
  font-weight: bolder;
  font-family: 'Tangerine', cursive;
  font-size: 40px;
}

.team-section {
  position: relative;
  padding: 80px 0px 60px;
}

.team-section.style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-position: center center;
  background-repeat: no-repeat;
}

.team-section .title-box {
  position: relative;
  text-align: center;
}

.team-section .title-box h2 {
  position: relative;
  color: #222222;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 50px;
}

.team-section .title-box h2 span {
  color: #ff9600;
}

.team-block {
  position: relative;
}

.team-block .inner-box {
  position: relative;
  /* padding:40px 70px 28px 0px; */
  padding: 14px 70px 14px 0px;
}

.team-block .inner-box:before {
  position: absolute;
  content: '';
  right: 0px;
  top: 0px;
  left: 70px;
  bottom: 0px;
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.team-block .inner-box::after {
  position: absolute;
  content: '';
  top: 0px;
  left: 70px;
  bottom: 0px;
  width: 0%;
  border-radius: 10px;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  background-image: -ms-linear-gradient(top, #ffca08 0%, #f70067 100%);
  background-image: -moz-linear-gradient(top, #ffca08 0%, #f70067 100%);
  background-image: -o-linear-gradient(top, #ffca08 0%, #f70067 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ffca08),
    color-stop(100, #f70067)
  );
  background-image: -webkit-linear-gradient(top, #ffca08 0%, #f70067 100%);
  background-image: linear-gradient(to bottom, #ffca08 0%, #f70067 100%);
}

.team-block .inner-box:hover::after {
  width: 81%;
}

.team-block .inner-box .social-icons {
  position: absolute;
  right: 25px;
  top: 100px;
  z-index: 1;
}

.team-block .inner-box .social-icons li {
  position: relative;
  margin-bottom: 20px;
}

.team-block .inner-box .social-icons li a {
  position: relative;
  color: #e40000;
  font-size: 18px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team-block .inner-box:hover .lower-content h3 a,
.team-block .inner-box:hover .social-icons li a,
.team-block .inner-box:hover .lower-content .designation {
  color: #ffffff;
}

.team-block .inner-box .image {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  /* background-color:#ffffff; */
  /* box-shadow:inset 0px 0px 35px rgba(0,0,0,0.20); */
}

.team-block .inner-box .image img {
  position: relative;
  width: 150px;
  display: block;
}

.team-block .inner-box .lower-content {
  position: relative;
  text-align: left;
  z-index: 1;
  padding: 30px 15px 0px 110px;
}

.team-block .inner-box .lower-content h3 {
  position: relative;
  color: #bd0000;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3em;
}

.team-block .inner-box .lower-content h3 a {
  position: relative;
  color: #bd0000;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team-block .inner-box .lower-content .designation {
  position: relative;
  margin-top: 5px;
  color: #777777;
  font-size: 14px;
}

.team-block.style-two .inner-box .lower-content h3 a,
.team-block.style-two .inner-box .social-icons li a {
  color: #ff9600;
}

.team-block.style-two .inner-box:hover .lower-content h3 a,
.team-block.style-two .inner-box:hover .social-icons li a {
  color: #ffffff;
}

.team-block.style-two .inner-box::after {
  background-image: -ms-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: -moz-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: -o-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ffeb3c),
    color-stop(100, #ff9600)
  );
  background-image: -webkit-linear-gradient(top, #ffeb3c 0%, #ff9600 100%);
  background-image: linear-gradient(to bottom, #ffeb3c 0%, #ff9600 100%);
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
