/* .brd {
    border: 1px solid red;
} */
.feed-services-section {
  margin-top: 20px;
  margin-bottom: 40px;
}

.feed-services-section .nav-link {
  padding: 0rem !important;
}
.card-img img {
  position: relative;
  z-index: 10;
  top: -55px;
  padding: 3px;
  border-radius: 50% 50%;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
}

.services-card {
  height: 200px;
  max-height: 200px;
  margin: 2 auto !important;
  border-radius: 10px 10px 10px 10px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.services-card img {
  width: 65px;
  height: 65px;
}

.services-card .services-card-header {
  height: 50px;
  border-radius: 10px 10px 50% 50%;
}

.card-body {
  padding: 0rem !important;
}
.services-card .card-body h5 {
  position: relative;
  height: 10px;
  top: -30px;
  font-weight: bold;
}

.wrapper {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
}

.item-flx {
  width: 100%;
}

@media only screen and (max-width: 426px) {
  .feed-services-section {
    margin-top: 2px;
    margin-bottom: 40px;
  }

  /* .feed-service-img {
        display: none !important;
    } */
  .rocket-ant {
    display: none !important;
  }

  .feed-services-section .nav-link {
    padding: 0rem !important;
  }
  .card-img img {
    position: relative;
    z-index: 10;
    top: -40px;
    width: 50 !important;
    padding: 5px;
    border-radius: 50% 50%;
    background-color: white;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  }

  .services-card {
    width: 80px;
    height: 80px;
    border-radius: 10px 10px 10px 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .item-flx {
    width: 90px;
    height: 90px;
  }

  .services-card img {
    margin: 5px;
    width: 40px;
    height: 40px;
  }

  .services-card .services-card-header {
    height: 30px;
    border-radius: 10px 10px 50% 50%;
  }
  .services-card .card-body {
    padding: 15px;
  }

  .services-card .card-body h5 {
    position: relative;
    /* height: 10px; */
    top: -30px;
    font-size: 10px;
    /* font-weight: bold; */
  }
}
