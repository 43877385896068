@import url('https://fonts.googleapis.com/css2?family=Orbitron:display=swap');

.feedWorkingHeading {
  font-size: 32px;
  font-family: 'Orbitron', sans-serif;
  margin: '50px 0px';
}

@media only screen and (max-width: 525px) {
  .video-wrap iframe {
    height: 250px;
  }
}
