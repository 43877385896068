.product-tumb {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: 150px;
  /* padding: 50px; */
  /* background: #f0f0f0; */
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  padding: 10px;
}
