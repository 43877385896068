/* 

span {
  font-size: 12px;
}

.lrContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 20px 20px;
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 580px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
  text-align: center;
}

.lrForm-reg-btn {
  border-radius: 20px;
  border: 1px solid #18608a;
  background-color: #18608a;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.lrForm-lgn-btn {
  border: 3px solid rgb(250, 187, 250);
  border-radius: 20px;
  background-color: #ff805d;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
    
.lrForm-gtl-btn {background-image: linear-gradient(to right, #c21500 0%, #ffc500  51%, #c21500  100%)}
.lrForm-gtl-btn {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
}

.lrForm-gtl-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
  
.lrForm-gtr-btn {background-image: linear-gradient(to right, #00c6ff 0%, #0072ff  51%, #00c6ff  100%)}
.lrForm-gtr-btn {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
}

.lrForm-gtr-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
 
input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
  
.lrForm-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.lrContainer.right-panel-active .sign-in-container {
  transform: translateX(100%);
}
  
.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.lrContainer.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}
  
@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.lrContainer.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.lrContainer.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.lrContainer.right-panel-active .overlay-left {
  transform: translateX(0);
  background-color: rgb(241, 161, 94);
}

.overlay-right {
  right: 0;
  background-color: rgb(240, 181, 252);
  transform: translateX(0);
}

.lrContainer.right-panel-active .overlay-right {
  transform: translateX(20%);
}
 */

.lg-form-wrap {
  width: calc(100vw - 100px);
  /* height: 600px; */
  margin: 8px auto 8px;
  /* border: 1px solid red; */
  padding: 10px;
  border-radius: 25px 25px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.lrForm-lgn-btn {
  border: none;
  /* border: 3px solid rgb(250, 187, 250); */
  border-radius: 20px;
  background-color: #ff805d;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
