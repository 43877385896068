.brd {
  border: 1px solid red;
}
.servicenav-wrap {
  /* box-shadow: 0px 0px 5px 0px rgba(126, 126, 126, 0.25) inset; */
  /* border-radius: 20px 20px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75); */
  margin: 10px auto;
}
.business-btn {
  background-color: #6739b7;
  color: white;
}
.farm-btn {
  background-color: #a4cf3e;
  color: white;
}

.export-btn {
  background-color: #64c5bc;
  color: white;
}

.wheat-btn {
  background-color: #dd8444;
  color: white;
}

.merun-btn {
  background-color: rgb(156, 57, 39);
}

.nav-btn .nav-link {
  padding: 0rem !important;
}

@media only screen and (max-width: 425px) {
  .resp-ServiceNav {
    display: none;
  }
}
